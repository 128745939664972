import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import { ShipmentChargeInputData } from "../../../../redux/slices/Types";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LocaleProvider from "../../../../providers/LocaleProvider";
import {
  CustomFieldContext,
  Shipment,
  TransactionInput,
  TransactionInputObject,
  TransactionType,
} from "../../../../graphql/generated";
import CustomFieldsFormContainer from "../../../extensions/CustomFieldsForm";
import DocumentsUploadModal from "../../../common/DocumentsUploadModal";
import { mileage } from "../../../../utils/conversion/distance";
import { capitalize } from "lodash";
import NumberTextField from "../../../common/NumberTextField";
import { useTranslation } from "react-i18next";
export interface TransactionFormProps {
  transaction: TransactionInput;
  transactionType?: TransactionType;
  onChange: (transaction: TransactionInput) => void;
  routeDistance?: number;
  routeDistanceWithDeadhead?: number;
  transactionTemplates?: TransactionInputObject[];
  isTemplate?: boolean;
  isFlatFee?: boolean;
  shipments?: Pick<Shipment, "_id" | "shipmentNumber">[];
}

const predefinedChargeLabels = [
  "Linehaul charge",
  "Loading Services",
  "Lumper Fee",
  "Extra Labor",
  "Detention with Tractor - Delivery",
  "Detention with Tractor - Pickup",
  "Detention without Tractor - Pickup",
  "Hazmat Charge",
  "Reconsignment",
  "Vehicle Furnished But Not used",
  "Layover/Overnight",
  "Tarp Charge",
  "Island Delivery Charge",
  "Canadian Border Crossing",
  "Weekend Temp Mgmt",
  "Southbound Mexico Border Crossing Non-Hazardous",
  "Southbound Mexico Border Crossing Hazardous",
  "Detention without Tractor - Delivery",
];
const predefinedExpenses = [
  "Fuel",
  "Tolls",
  "Parking",
  "Maintenance",
  "Repairs",
  "Other",
];
const predefinedUnits = [
  "Hours",
  `${capitalize(LocaleProvider.getMileageUnit())}s (loaded)`,
  `${capitalize(LocaleProvider.getMileageUnit())}s (empty)`,
  `${capitalize(LocaleProvider.getMileageUnit())}s (both)`,
  "Gallons",
  "Flat Fee",
];

const mileagePredefinedUnitIndex = 1;
const mileageEmptyPredefinedUnitIndex = 2;
const mileageWithDeadheadPredefinedUnitIndex = 3;

export default function TransactionForm({
  transaction,
  transactionType = TransactionType.Expense,
  onChange,
  routeDistance,
  routeDistanceWithDeadhead,
  transactionTemplates,
  isTemplate,
  isFlatFee,
  shipments,
}: TransactionFormProps) {
  const { t } = useTranslation("finance");
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);
  const chargeTemplates = transactionTemplates?.filter(
    (t) => t.type === TransactionType.Income
  );
  const expenseTemplates = transactionTemplates?.filter(
    (t) => t.type === TransactionType.Expense
  );
  const applicableTemplates =
    transactionType === TransactionType.Expense
      ? expenseTemplates
      : chargeTemplates;
  const predefinedLabels = applicableTemplates?.length
    ? applicableTemplates.map((t) => t.label)
    : transactionType === TransactionType.Expense
    ? predefinedExpenses
    : predefinedChargeLabels;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    const updatedTransaction: ShipmentChargeInputData = {
      ...transaction,
      [name as keyof ShipmentChargeInputData]:
        name === "unit" || name === "rate" ? parseFloat(value) || 0 : value,
      type: transactionType,
    };
    onChange(updatedTransaction);
  };

  return (
    <Stack spacing={2}>
      <Box component="form">
        {shipments && shipments.length > 1 ? (
          <Select value={transaction.shipmentId || null}>
            {shipments.map((shipment) => (
              <MenuItem key={shipment._id} value={shipment._id}>
                Order {shipment.shipmentNumber}
              </MenuItem>
            ))}
          </Select>
        ) : null}
        <Autocomplete
          options={isTemplate ? [] : predefinedLabels}
          freeSolo={isTemplate || !applicableTemplates?.length}
          size="small"
          fullWidth
          value={transaction.label || ""}
          renderInput={(inputParams) => (
            <TextField
              {...inputParams}
              name="label"
              label={t("label", "Label")}
              onChange={handleChange}
            />
          )}
          onChange={(event, value) => {
            const templateTransaction = transactionTemplates?.find(
              (t) => t.label === value
            );
            const predefinedUnitIndex = value
              ? predefinedUnits.indexOf(value)
              : -1;
            if (!isTemplate && templateTransaction) {
              onChange({
                ...transaction,
                label: value || "",
                rate: templateTransaction.rate,
                unitType: templateTransaction.unitType,
                unit:
                  templateTransaction.unitType === "Flat Fee"
                    ? 1
                    : predefinedUnitIndex === mileagePredefinedUnitIndex &&
                      routeDistance !== undefined
                    ? mileage(routeDistance)
                    : predefinedUnitIndex === mileageEmptyPredefinedUnitIndex &&
                      routeDistanceWithDeadhead !== undefined &&
                      routeDistance !== undefined
                    ? mileage(routeDistanceWithDeadhead - routeDistance)
                    : predefinedUnitIndex ===
                        mileageWithDeadheadPredefinedUnitIndex &&
                      routeDistanceWithDeadhead !== undefined
                    ? mileage(routeDistanceWithDeadhead)
                    : transaction.unit,
                reimburseToDriver: templateTransaction.reimburseToDriver,
                _id: transaction._id,
                type: transactionType,
              });
            } else {
              onChange({
                ...transaction,
                label: value || "",
              });
            }
          }}
        />
      </Box>
      <Stack
        component="form"
        sx={{ display: "flex" }}
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={1}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ display: "flex" }}>
          {isTemplate || isFlatFee ? null : (
            <TextField
              name="unit"
              type="number"
              value={String(transaction.unit) || ""}
              disabled={transaction.unitType === "Flat Fee"}
              onChange={handleChange}
              size="small"
              label={t("finance:quantity", "Quantity")}
            />
          )}
          {isFlatFee ? null : (
            <Autocomplete
              options={predefinedUnits}
              freeSolo
              size="small"
              sx={{
                width: 150,
              }}
              value={transaction.unitType || ""}
              renderInput={(inputParams) => (
                <TextField
                  {...inputParams}
                  name="unitType"
                  label={t("finance:unit", "Unit")}
                  onChange={(event) => {
                    const value = event.target.value;
                    onChange({
                      ...transaction,
                      unitType: value || "",
                      unit: value === "Flat Fee" ? 1 : transaction.unit,
                    });
                  }}
                />
              )}
              onChange={(event, value) => {
                const predefinedUnitIndex = value
                  ? predefinedUnits.indexOf(value)
                  : -1;
                onChange({
                  ...transaction,
                  unitType: value || "",
                  unit:
                    value === "Flat Fee"
                      ? 1
                      : predefinedUnitIndex === mileagePredefinedUnitIndex &&
                        routeDistance !== undefined
                      ? mileage(routeDistance)
                      : predefinedUnitIndex ===
                          mileageEmptyPredefinedUnitIndex &&
                        routeDistanceWithDeadhead !== undefined &&
                        routeDistance !== undefined
                      ? mileage(routeDistanceWithDeadhead - routeDistance)
                      : predefinedUnitIndex ===
                          mileageWithDeadheadPredefinedUnitIndex &&
                        routeDistanceWithDeadhead !== undefined
                      ? mileage(routeDistanceWithDeadhead)
                      : transaction.unit,
                });
              }}
            />
          )}
        </Box>
        <NumberTextField
          name="rate"
          value={String(transaction.rate) || ""}
          onChange={handleChange}
          size="small"
          inputProps={{
            type: "number",
            min: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {LocaleProvider.getCurrencySymbol()}
              </InputAdornment>
            ),
          }}
          label={
            isFlatFee
              ? t("finance:amount", "Amount")
              : t("finance:rate", "Rate")
          }
        />
        {isTemplate || isFlatFee ? null : (
          <NumberTextField
            name="total"
            value={
              String(
                parseFloat((transaction.rate * transaction.unit).toFixed(2))
              ) || ""
            }
            onChange={(event) => {
              onChange({
                ...transaction,
                rate: transaction.unit
                  ? parseFloat(
                      (
                        (parseFloat(event.target.value) || 0) / transaction.unit
                      ).toFixed(2)
                    )
                  : 0,
              });
            }}
            size="small"
            inputProps={{
              type: "number",
              min: 0,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {LocaleProvider.getCurrencySymbol()}
                </InputAdornment>
              ),
            }}
            label={t("finance:total", "Total")}
          />
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        {transactionType === TransactionType.Expense ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={transaction.reimburseToDriver || false}
                onChange={(event, checked) => {
                  onChange({
                    ...transaction,
                    reimburseToDriver: checked,
                  });
                }}
                name="addToSettlement"
              />
            }
            label={t("finance:addToSettlement", "Add to Settlement")}
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!transaction.taxable}
                onChange={(event, checked) => {
                  onChange({
                    ...transaction,
                    taxable: checked,
                  });
                }}
              />
            }
            label={t("taxable")}
          />
        )}
        {isTemplate ? null : (
          <Stack>
            {transaction.document ? (
              <Typography variant="caption">
                {transaction.document.name}
              </Typography>
            ) : null}
            <Button
              variant="outlined"
              onClick={() => setIsUploadModalOpen(true)}
            >
              {transaction.document
                ? t("finance:updateDocument", "Update Document")
                : t("finance:uploadDocument", "Upload Document")}
            </Button>
          </Stack>
        )}
      </Stack>
      <Box>
        {!transactionType || transactionType === TransactionType.Expense ? (
          <CustomFieldsFormContainer
            context={CustomFieldContext.Expense}
            customFields={transaction.customFields || []}
            onChange={(customFields) => {
              onChange({ ...transaction, customFields });
            }}
          />
        ) : null}
      </Box>
      <DocumentsUploadModal
        isOpen={isUploadModalOpen}
        onCancel={() => setIsUploadModalOpen(false)}
        onSubmit={(documents) => {
          onChange({
            ...transaction,
            document: documents[0],
          });
          setIsUploadModalOpen(false);
        }}
        multiple={false}
        title={t("finance:addDocument", "Add Document")}
      />
    </Stack>
  );
}

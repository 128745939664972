import {
  GetShipmentsForBestBuyBulkUpdateQuery,
  PurchaseInput,
} from "../../../graphql/generated";
import BestBuyModal, { BestBuyModalProps } from "../BestBuyModal/BestBuyModal";

type ShipmentForBestBuyBulkUpdate =
  GetShipmentsForBestBuyBulkUpdateQuery["shipments"]["data"][0];

export type BestBuyBulkUpdateModalProps = Omit<
  BestBuyModalProps,
  "onBestBuyItemSubmit" | "purchase"
> & {
  shipments: ShipmentForBestBuyBulkUpdate[];
  onSubmit: (shipmentIds: Array<string>, contractId: string) => void;
};

const BestBuyBulkUpdateModal = ({
  onSubmit,
  shipments,
  ...props
}: BestBuyBulkUpdateModalProps) => {
  const purchases: PurchaseInput[] = shipments
    .flatMap((s) =>
      s.shipmentLocations.flatMap((sl) =>
        (sl.shippedGoods || []).map((sg) => ({
          ...sg,
          shipment: s,
          shipmentLocation: sl,
        }))
      )
    )
    .filter((sg) => sg.goodProfileId)
    .map((shippedGood) => {
      const receiverLocation = shippedGood.shipment.shipmentLocations.find(
        (sl) =>
          sl.receivedGoods?.some(
            (rg) => rg.goodProfileId === shippedGood.goodProfileId
          )
      );
      if (!receiverLocation) {
        throw new Error("Receiver location not found");
      }
      return {
        customerId: shippedGood.shipment.customer._id,
        date: shippedGood.shipment.route?.lastDropoffTime,
        dropoffLocation: receiverLocation.location,
        goodProfileId: shippedGood.goodProfileId as string,
        quantity: shippedGood.quantity,
      };
    });
  return (
    <BestBuyModal
      {...props}
      purchase={purchases}
      onBestBuyItemSubmit={(bestBuyItem) => {
        onSubmit(
          shipments.map((s) => s._id),
          bestBuyItem.supplierContract._id
        );
      }}
    />
  );
};

export default BestBuyBulkUpdateModal;

import {
  Compress,
  DoneAll,
  ForwardToInbox,
  Loop,
  PanTool,
  RestorePage,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { round, sum } from "lodash";
import { useMemo, useState } from "react";
import {
  ExtensionOperationContext,
  GetInvoiceListQuery,
  InvoiceStatus,
} from "../../../graphql/generated";
import LocaleProvider from "../../../providers/LocaleProvider";
import useDialog from "../../../utils/hooks/useDialog";
import { TableField } from "../../common/LynksTable/LynksTable";
import ShipmentLink from "../../common/ShipmentLink";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import { useTranslation } from "react-i18next";
import { useConfirm } from "material-ui-confirm";
import invoiceStatusLabel from "../../../utils/labels/invoiceStatusLabel";

export type InvoiceListItemData = GetInvoiceListQuery["invoices"]["data"][0];
type InvoiceListProps = {
  queryHook: QueryFn<InvoiceListItemData, "invoices">;
  deleteMutation: DeleteMutation;
  onSendInvoice: (invoice: InvoiceListItemData) => void;
  onInvoiceStatusUpdate: (
    invoice: InvoiceListItemData,
    status: InvoiceStatus,
    closingNote?: string
  ) => void;
  onInvoicesConsolidate: (invoices: InvoiceListItemData[]) => void;
};

export enum InvoiceStatusTab {
  NOT_INVOICED,
  IN_PROCESS,
  ON_HOLD,
  TRANSFERRED,
  INVOICED,
  CANCELLED,
}

const statusToInvoiceStatus: {
  [key in InvoiceStatusTab]: InvoiceStatus | null;
} = {
  [InvoiceStatusTab.NOT_INVOICED]: InvoiceStatus.NotInvoiced,
  [InvoiceStatusTab.IN_PROCESS]: InvoiceStatus.Draft,
  [InvoiceStatusTab.TRANSFERRED]: InvoiceStatus.Transferred,
  [InvoiceStatusTab.ON_HOLD]: InvoiceStatus.OnHold,
  [InvoiceStatusTab.INVOICED]: InvoiceStatus.Closed,
  [InvoiceStatusTab.CANCELLED]: InvoiceStatus.Cancelled,
};

const InvoiceList = ({
  queryHook: query,
  deleteMutation,
  onSendInvoice,
  onInvoiceStatusUpdate,
  onInvoicesConsolidate,
}: InvoiceListProps) => {
  const { showDialog, hideDialog } = useDialog();
  const [closingNoteModalOpen, setClosingNoteModalOpen] = useState(false);
  const [selectedInvoiceForClosing, setSelectedInvoiceForClosing] =
    useState<InvoiceListItemData | null>(null);
  const [closingNote, setClosingNote] = useState("");
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.NOT_INVOICED
  );
  const { t } = useTranslation(["finance", "common"]);
  const [selected, setSelected] = useState<InvoiceListItemData[]>([]);
  const confirm = useConfirm();

  const fields: Array<TableField<InvoiceListItemData, InvoiceStatus>> = useMemo(
    () => [
      {
        label: "Status",
        type: "enum",
        value: (invoice) => invoice.status,
        valueLabel: invoiceStatusLabel,
        colors: {
          [InvoiceStatus.Draft]: "warning",
          [InvoiceStatus.Sent]: "primary",
          [InvoiceStatus.Paid]: "success",
          [InvoiceStatus.Cancelled]: "error",
          [InvoiceStatus.Closed]: "success",
          [InvoiceStatus.NotInvoiced]: "warning",
          [InvoiceStatus.OnHold]: "warning",
          [InvoiceStatus.Transferred]: "success",
        },
      },
      {
        label: t("order"),
        type: "custom",
        value: (invoice) => (
          <Stack direction="row" spacing={2}>
            {(invoice.shipments || []).map((shipment) => (
              <ShipmentLink shipment={shipment} />
            ))}
          </Stack>
        ),
        sortBy: "shipmentIds",
      },
      {
        label: t("invoiceNumber"),
        type: "number",
        value: "invoiceNumber",
        sortBy: "invoiceNumber",
      },
      {
        label: t("amount"),
        type: "string",
        value: (invoice) =>
          invoice.status !== InvoiceStatus.NotInvoiced
            ? `${LocaleProvider.getCurrencySymbol()} ${round(
                sum(invoice.charges.map((charge) => charge.unit * charge.rate)),
                2
              )}`
            : "",
      },
      {
        label: t("customer"),
        type: "custom",
        value: (invoice) =>
          invoice.customer ? (
            <Link href={`/customers/details/${invoice.customer._id}`}>
              {invoice.customer.name}
            </Link>
          ) : null,
      },
      {
        label: t("shipper", "Shipper"),
        type: "custom",
        value: (invoice) => {
          const shippers = (invoice.shipments || []).flatMap((s) =>
            s.shipmentLocations.map((l) => l.shipper)
          );
          return (
            <Stack
              direction="row"
              alignItems="center"
              divider={
                <Typography
                  sx={{
                    mx: 0.5,
                  }}
                >
                  {" "}
                  /{" "}
                </Typography>
              }
            >
              {shippers.map((shipper) =>
                shipper ? (
                  <>
                    <Link href={`/locations/details/${shipper._id}`}>
                      {shipper.name}
                    </Link>
                  </>
                ) : null
              )}
            </Stack>
          );
        },
      },
      {
        label: t("deliveryDate", "Delivery Date"),
        type: "datetime",
        value: (invoice) => invoice.shipments?.[0]?.trip?.lastDropoffTime,
      },
      {
        label: t("poNumber", "PO Number"),
        type: "string",
        value: (invoice) =>
          invoice.shipments
            ?.map((s) => s.purchaseOrderNumbers || [])
            .join(", "),
      },
      {
        label: t("referenceNumbers", "Reference Numbers"),
        type: "string",
        value: (invoice) =>
          invoice.shipments
            ?.flatMap((s) => s.referenceNumbers || [])
            .join(", "),
      },
      {
        label: t("bolNumbers", "BOL Numbers"),
        type: "string",
        value: (invoice) =>
          invoice.shipments
            ?.flatMap((s) => s.billOfLadingNumbers || [])
            .join(", "),
      },
      {
        label: t("ticketNumbers", "Ticket Numbers"),
        type: "string",
        value: (invoice) =>
          invoice.shipments?.flatMap((s) => s.ticketNumbers || []).join(", "),
      },
    ],
    [t]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={statusTab}
          onChange={(event, value) => {
            setStatusTab(value);
          }}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab
            label={t("notInvoiced", "Not invoiced")}
            value={InvoiceStatusTab.NOT_INVOICED}
          />
          <Tab
            label={t("inProcess", "In process")}
            value={InvoiceStatusTab.IN_PROCESS}
          />
          <Tab
            label={t("invoiced", "Invoiced")}
            value={InvoiceStatusTab.INVOICED}
          />
          <Tab
            label={t("transferred", "Transferred")}
            value={InvoiceStatusTab.TRANSFERRED}
          />
          <Tab
            label={t("onHold", "On hold")}
            value={InvoiceStatusTab.ON_HOLD}
          />
          <Tab
            label={t("cancelled", "Cancelled")}
            value={InvoiceStatusTab.CANCELLED}
          />
        </Tabs>
      </Box>
      <SmartLynksTable
        query={query}
        deleteMutation={deleteMutation}
        dataKey="invoices"
        id="invoice-list"
        fields={fields}
        extensionOperationContext={ExtensionOperationContext.InvoiceDetails}
        additionalQueryVariables={{
          status: statusToInvoiceStatus[statusTab],
        }}
        detailsUrlPrefix={`/invoices/details`}
        selectable
        onSelect={setSelected}
        showGroups
        actions={[
          {
            icon: <ForwardToInbox />,
            tooltip: t("sendInvoice"),
            isApplicable: (invoice) =>
              invoice.status !== InvoiceStatus.NotInvoiced,
            onClick: (invoice) => {
              if (!invoice.customer?.contact.email) {
                showDialog({
                  type: "error",
                  title: t("common:error.title"),
                  description: t("customerNoEmail"),
                });
                return;
              }
              showDialog({
                type: "primary",
                title: t("sendInvoice"),
                description: t("sendInvoiceDescription", {
                  customerName: invoice.customer.name,
                  customerEmail: invoice.customer.contact.email,
                }),
                actions: [
                  {
                    title: t("common:cancel"),
                    type: "secondary",
                  },
                  {
                    title: t("common:send", "Send"),
                    type: "primary",
                    onClick: () => {
                      onSendInvoice(invoice);
                      hideDialog();
                    },
                  },
                ],
              });
            },
          },
          {
            icon: <Loop />,
            tooltip: t("processInvoice"),
            isApplicable: (invoice) =>
              invoice.status === InvoiceStatus.Draft ||
              invoice.status === InvoiceStatus.OnHold,
            onClick: (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.Sent);
            },
          },
          {
            icon: <PanTool />,
            tooltip: t("putInvoiceOnHold"),
            isApplicable: (invoice) =>
              invoice.status === InvoiceStatus.Draft ||
              invoice.status === InvoiceStatus.Sent,
            onClick: (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.OnHold);
            },
          },
          {
            icon: <DoneAll />,
            tooltip: t("closeInvoice"),
            isApplicable: (invoice) =>
              invoice.status !== InvoiceStatus.Closed &&
              invoice.status !== InvoiceStatus.NotInvoiced,
            onClick: (invoice) => {
              setSelectedInvoiceForClosing(invoice);
              setClosingNoteModalOpen(true);
            },
          },
          {
            icon: <RestorePage />,
            isApplicable: (invoice) => invoice.status === InvoiceStatus.Closed,
            tooltip: t("reopenInvoice"),
            onClick: async (invoice) => {
              onInvoiceStatusUpdate(invoice, InvoiceStatus.Draft, "");
            },
          },
        ]}
        bulkActionMenus={[
          {
            icon: <Compress />,
            id: "consolidate",
            label: t("consolidate", "Consolidate"),
            onClick: async () => {
              try {
                await confirm({
                  title: t("consolidateInvoices", "Consolidate Invoices"),
                  description: t(
                    "consolidateInvoicesDescription",
                    "Are you sure you want to consolidate these invoices ({{invoiceNumbers}}) ?",
                    {
                      invoiceNumbers: selected
                        .map((invoice) => invoice.invoiceNumber)
                        .join(", "),
                    }
                  ),
                });
                onInvoicesConsolidate(selected);
              } catch (error) {
                if (error) {
                  console.error(error);
                  throw error;
                }
              }
            },
          },
        ]}
      />
      <Dialog open={closingNoteModalOpen}>
        <DialogTitle>{t("invoiceClosingNote")}</DialogTitle>
        <DialogContent
          sx={{
            pt: 1,
          }}
        >
          <Stack sx={{ mt: 1 }}>
            <TextField
              label={t("closingNote")}
              value={closingNote}
              onChange={(e) => {
                setClosingNote(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setClosingNoteModalOpen(false);
              setClosingNote("");
            }}
          >
            {t("common:cancel")}
          </Button>
          <Button
            onClick={() => {
              if (!selectedInvoiceForClosing) {
                return;
              }
              setClosingNoteModalOpen(false);
              onInvoiceStatusUpdate(
                selectedInvoiceForClosing,
                InvoiceStatus.Closed,
                closingNote
              );
              setClosingNote("");
            }}
            variant="contained"
            id="save-closing-note"
          >
            {t("common:save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceList;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  Address,
  AssetStatus,
  BusinessEntity,
  BusinessEntityType,
  GetBusinessEntitiesQuery,
  NewBusinessEntityInputWithType,
} from "../../../../graphql/generated";
import { capitalize, sortBy } from "lodash";
import BusinessEntityFormContainer from "../../../asset-management/BusinessEntityForm";
import enumLabel from "../../../../utils/labels/enumLabel";
import PlacesAutocomplete from "react-places-autocomplete";
import { gmapsPlaceToAddress } from "../../../asset-management/AddressInput/AddressInput";
import { useTranslation } from "react-i18next";
import businessEntityLabelKey from "../../../../utils/i18n/businessEntityLabelKey";

enum AutocompleteOptionType {
  BusinessEntity = "BusinessEntity",
  Address = "Address",
}

type AutocompleteOption = {
  id: string;
  label: string;
  type: AutocompleteOptionType;
  status: AssetStatus;
};

export type SelectBusinessEntity = Pick<
  NonNullable<GetBusinessEntitiesQuery["businessEntities"]["data"][0]>,
  | "name"
  | "_id"
  | "address"
  | "openingSchedules"
  | "code"
  | "mcNumber"
  | "type"
  | "additionalTypes"
  | "addressTimezone"
  | "storageFacilities"
  | "status"
>;

export type BusinessEntitySelectProps = Omit<
  AutocompleteProps<string, undefined, undefined, undefined>,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
> & {
  businessEntityType?: BusinessEntityType | null;
  businessEntityTypes?: BusinessEntityType[] | null;
  businessEntities?: Array<SelectBusinessEntity>;
  createBusinessEntity: (
    be: NewBusinessEntityInputWithType
  ) => Promise<Pick<BusinessEntity, "_id"> | null>;
  onSearchChange: (search: string) => void;
  onBusinessEntitiesRefetch: () => Promise<void>;
  onChange: (
    businessEntityId: string | null,
    businessEntity?: SelectBusinessEntity | null
  ) => void;
  onAddressSelect?: (address: Address | null) => void;
  initialAddressLabel?: string | null;
  inputProps?: TextFieldProps;
  allowAddress?: boolean;
  allowNew?: boolean;
};

export default function BusinessEntitySelect({
  businessEntityType,
  businessEntities,
  onSearchChange,
  onBusinessEntitiesRefetch,
  createBusinessEntity,
  inputProps: providedInputProps,
  onChange,
  onAddressSelect,
  initialAddressLabel,
  allowAddress,
  allowNew = true,
  ...props
}: BusinessEntitySelectProps) {
  const { t } = useTranslation(["business", "common"]);
  const [isNewBusinessEntityModalOpen, setIsNewBusinessEntityModalOpen] =
    useState(false);

  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState(initialAddressLabel || "");

  const onSelect = (address: string, placeID: string) => {
    const placesService = new google.maps.places.PlacesService(
      new google.maps.Map(document.createElement("div"))
    );
    placesService.getDetails(
      {
        placeId: placeID,
      },
      async (place) => {
        if (!place) {
          return;
        }
        const location = place.geometry?.location;
        const lat = location?.lat();
        const lng = location?.lng();
        if (!lat || !lng) {
          return;
        }
        const address = gmapsPlaceToAddress(place);
        if (!address) {
          return;
        }
        onAddressSelect?.(address);

        // // We need to blur the autocomplete input so the new entity
        // // shows up as selected
        // setTimeout(() => {
        //   autocompleteRef.current?.querySelector("input")?.blur();
        // });
      }
    );
  };

  const onAutocompleteInputChange = useCallback(
    (newAddress: string) => {
      setInputValue(newAddress);
      if (!newAddress) {
        onChange(null);
      }
    },
    [onChange]
  );

  const getBeOptionName = useCallback(
    (businessEntityId: string) => {
      if (businessEntityId === "NEW") {
        if (!businessEntityType) {
          return "Add new location";
        }
        return t("addNewBusiness", {
          business: businessEntityLabelKey[businessEntityType],
          defaultValue: `Add new ${enumLabel(businessEntityType)}`,
        });
      }
      const businessEntity = businessEntities?.find(
        (be) => be._id === businessEntityId
      );
      if (!businessEntity) {
        return "";
      }
      const allTypes = [
        businessEntity.type,
        ...(businessEntity.additionalTypes || []),
      ];
      const isBroker = allTypes.includes(BusinessEntityType.Broker);
      return `${businessEntity.code ? `${businessEntity.code} - ` : ""}${
        isBroker && businessEntity.mcNumber
          ? `${businessEntity.mcNumber} - `
          : ""
      }${businessEntity.name}`;
    },
    [businessEntities, businessEntityType, t]
  );

  useEffect(() => {
    if (props.value) {
      setInputValue(getBeOptionName(props.value));
    }
  }, [props.value, getBeOptionName]);

  return (
    <Box sx={props.sx}>
      <PlacesAutocomplete
        value={inputValue}
        onChange={onAutocompleteInputChange}
        onSelect={onSelect}
      >
        {({ getInputProps, suggestions, loading }) => {
          const inputProps = getInputProps();

          const rolodexAutocompleteOptions = sortBy(
            businessEntities || [],
            (be) => be.name
          ).map((be) => ({
            id: be._id,
            label: getBeOptionName(be._id),
            type: AutocompleteOptionType.BusinessEntity,
            status: be.status,
          }));
          const addressAutocompleteOptions = suggestions.map((suggestion) => ({
            id: suggestion.placeId,
            label: suggestion.description,
            type: AutocompleteOptionType.Address,
            status: AssetStatus.Active,
          }));

          const autocompleteOptions: AutocompleteOption[] =
            rolodexAutocompleteOptions.concat(addressAutocompleteOptions);

          return (
            <Autocomplete
              disablePortal
              options={autocompleteOptions}
              freeSolo
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.status === AssetStatus.Inactive
                  ? `${option.label} (${t("common:statusTypes.INACTIVE")})`
                  : option.label
              }
              getOptionDisabled={(option) =>
                option.status === AssetStatus.Inactive
              }
              filterOptions={(options, params) => {
                const filtered = options.filter(
                  (option) =>
                    option.type === AutocompleteOptionType.BusinessEntity
                );
                return filtered
                  .concat(allowAddress ? addressAutocompleteOptions : [])
                  .concat(
                    allowNew
                      ? {
                          id: "NEW",
                          label: getBeOptionName("NEW"),
                          type: AutocompleteOptionType.BusinessEntity,
                          status: AssetStatus.Active,
                        }
                      : []
                  );
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  return;
                }
                if (!newValue) {
                  onChange(null);
                  setInputValue("");
                } else if (newValue?.id === "NEW") {
                  setIsNewBusinessEntityModalOpen(true);
                  setTimeout(() => {
                    setInputValue("");
                  });
                } else if (
                  newValue?.type === AutocompleteOptionType.BusinessEntity
                ) {
                  onChange?.(
                    newValue.id || null,
                    businessEntities?.find((be) => be._id === newValue.id)
                  );
                  setInputValue(newValue.label);
                } else {
                  onSelect(inputValue, newValue.id);
                  setInputValue(newValue.label);
                }
              }}
              size="small"
              renderInput={(inputProps) => (
                <TextField
                  name={enumLabel(businessEntityType)?.toLowerCase() || ""}
                  required
                  label={
                    businessEntityType
                      ? capitalize(
                          t("entityLabel", {
                            business:
                              businessEntityLabelKey[businessEntityType],
                            defaultValue: enumLabel(businessEntityType),
                          })
                        )
                      : props.placeholder || "Location"
                  }
                  {...inputProps}
                  {...providedInputProps}
                />
              )}
              inputValue={inputProps.value || ""}
              onInputChange={(event, textValue) => {
                inputProps.onChange({
                  target: {
                    value: textValue,
                  },
                });
                onSearchChange(textValue);
              }}
              ref={autocompleteRef}
            />
          );
        }}
      </PlacesAutocomplete>

      <Dialog
        open={isNewBusinessEntityModalOpen}
        onClose={() => {
          setIsNewBusinessEntityModalOpen(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>
          {businessEntityType
            ? capitalize(
                t("newBusiness", {
                  business: businessEntityLabelKey[businessEntityType],
                  defaultValue: `New ${enumLabel(businessEntityType)}`,
                })
              )
            : "New Location"}
        </DialogTitle>
        <DialogContent>
          <BusinessEntityFormContainer
            defaultType={businessEntityType || undefined}
            navigateAfterSave={false}
            onSaved={(businessEntity) => {
              setIsNewBusinessEntityModalOpen(false);
              onBusinessEntitiesRefetch();
              onChange(businessEntity._id);
            }}
            minimal
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

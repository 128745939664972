import {
  DeleteOutlineOutlined,
  ModeEditOutlineOutlined,
  Email,
  Person,
  ManageAccounts,
  LocalShipping,
  Style,
  LockOutlined,
  LockOpen,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { GetOrgUserDetailsQuery } from "../../../graphql/generated";
import roleLabel from "../../../utils/labels/roleLabel";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import LoadingOverlay from "../../common/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import RoleGuard from "../Access/RoleGuard";

type UserDetailsData = GetOrgUserDetailsQuery["orgUserById"];

type UserDetailsProps = {
  user: UserDetailsData;
  onEdit: () => void;
  onDelete: () => void;
  updatingPassword: boolean;
  onPasswordUpdate: (password: string) => void;
  onUserAccountStatusUpdate: (enabled: boolean) => void;
};

function UserDetails({
  user,
  onEdit,
  onDelete,
  updatingPassword,
  onPasswordUpdate,
  onUserAccountStatusUpdate,
}: UserDetailsProps) {
  const { t } = useTranslation(["users", "common"]);
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  return (
    <Box>
      {user && !user.enabled && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography variant="body2" color="error">
            {t(
              "disabledDesc",
              "This user disabled. He cannot login to his account."
            )}
          </Typography>
        </Alert>
      )}
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <RoleGuard roles={["Carrier Admin"]}>
          <IconButton onClick={onDelete} color="error">
            <DeleteOutlineOutlined />
          </IconButton>
        </RoleGuard>
        {user && (
          <IconButton
            onClick={() => onUserAccountStatusUpdate(!user.enabled)}
            color={user.enabled ? "error" : "success"}
          >
            <Tooltip
              title={t(
                user.enabled ? "disable" : "enable",
                user.enabled ? "Disable" : "Enable"
              )}
            >
              {user.enabled ? <LockOutlined /> : <LockOpen />}
            </Tooltip>
          </IconButton>
        )}
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
        <LoadingButton
          variant="outlined"
          loading={updatingPassword}
          disabled={updatingPassword}
          onClick={() => {
            setIsUpdatePasswordModalOpen(true);
          }}
        >
          {t("resetPassword", "Reset password")}
        </LoadingButton>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <InfoBlock title={t("details", "User Details")}>
            <LabeledInfo
              title={t("firstName", "First name")}
              value={user.firstName}
              icon={<Person />}
            />
            <LabeledInfo
              title={t("lastName", "Last name")}
              value={user.lastName}
              icon={<Person />}
            />
            <LabeledInfo
              title={t("email", "Email")}
              value={user.email}
              icon={<Email />}
            />
            <LabeledInfo
              title={t("roles", "Roles")}
              value={user.roles.map(roleLabel).join(", ")}
              icon={<ManageAccounts />}
            />
            {user.roles.find((role) => role.name === "Carrier Driver") ? (
              <LabeledInfo
                title={capitalize(t("driver.profile", "Driver Profile"))}
                value={
                  user.attributes.driverId ? (
                    <Link href={`/drivers/details/${user.attributes.driverId}`}>
                      {t("driver.openProfile", "Open Driver Profile")}
                    </Link>
                  ) : (
                    "N/A"
                  )
                }
                icon={<LocalShipping />}
              />
            ) : null}

            <LabeledInfo
              title={t("common:tags")}
              value={
                <ChipTagsList
                  value={
                    user.attributes.tags ? user.attributes.tags.split(",") : []
                  }
                />
              }
              icon={<Style />}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <CustomFieldsDisplayContainer
            customFields={
              user.attributes.customFields
                ? JSON.parse(user.attributes.customFields)
                : []
            }
          />
        </Grid>
      </Grid>
      <Dialog
        open={isUpdatePasswordModalOpen}
        onClose={() => setIsUpdatePasswordModalOpen(false)}
      >
        <LoadingOverlay loading={updatingPassword} />
        <DialogTitle>{t("updatePassword", "Update user password")}</DialogTitle>
        <DialogContent dividers>
          <TextField
            label={t("password", "Password")}
            type="password"
            name="password"
            required
            fullWidth
            helperText="User will need to change his password on his next login"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!newPassword}
            variant="contained"
            onClick={() => {
              onPasswordUpdate(newPassword);
              setIsUpdatePasswordModalOpen(false);
              setNewPassword("");
            }}
          >
            {t("update", "Update")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default UserDetails;

import Joi from "joi";
import { NewGoodProfileInput } from "../../../graphql/generated";
import customFieldSchema from "../../extensions/CustomFieldsForm/customFieldsSchema";
import documentSchema from "../../../utils/form/schemas/document.schema";

const goodProfileSchema = Joi.object<NewGoodProfileInput>({
  label: Joi.string().min(3).max(50).required().label("Label"),
  code: Joi.string().label("License Number").allow(null, ""),
  unit: Joi.string().label("Unit").required(),
  weight: Joi.number().label("Weight Per Unit").required(),
  liquidGravity: Joi.number().label("Liquid Gravity").allow(null),
  supplierIds: Joi.array().items(Joi.string()).label("Suppliers").allow(null),
  shipperIds: Joi.array().items(Joi.string()).label("Shippers").allow(null),
  equivalences: Joi.array().items(Joi.any()).label("Equivalences").allow(null),
  restrictions: Joi.array().items(Joi.any()).label("Restrictions").allow(null),
  color: Joi.string().allow(null),
  goodProfileClassId: Joi.string().label("Class").allow(null),
  tags: Joi.array().items(Joi.string()).label("Tags").allow(null),
  customFields: Joi.array()
    .items(customFieldSchema)
    .label("Custom Fields")
    .allow(null),
  groupIds: Joi.array().items(Joi.string()).label("Group IDs").allow(null),
  documents: Joi.array().items(documentSchema).label("Documents").allow(null),
});

export default goodProfileSchema;

import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import {
  GetMaintenanceTaskDetailsQuery,
  MaintenanceTaskStatus,
  NewMaintenanceTaskInput,
  useAddMaintenanceTaskMutation,
  useEditMaintenanceTaskMutation,
  useGetMaintenanceTaskDetailsQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import MaintenanceTaskForm, {
  MaintenanceTaskFormProps,
} from "./MaintenanceTaskForm";
import { TractorDetailsData } from "../../asset-management/TractorDetails/TractorDetails";
import { TrailerDetailsData } from "../../asset-management/TrailerDetails/TrailerDetails";

type MaintenanceTaskFormContainerProps = Omit<
  MaintenanceTaskFormProps,
  "onSave" | "saving"
> & {
  maintenanceTaskId?: string;
  onLoad?: (
    maintenanceTask: GetMaintenanceTaskDetailsQuery["maintenanceTaskById"]
  ) => void;
  onSaved?: (
    maintenanceTask: GetMaintenanceTaskDetailsQuery["maintenanceTaskById"]
  ) => void;
  asset?: TractorDetailsData | TrailerDetailsData;
};

function MaintenanceTaskFormContainer({
  maintenanceTaskId,
  onLoad,
  onSaved,
  asset,
  ...props
}: MaintenanceTaskFormContainerProps) {
  const addMaintenanceTaskMutation = useAddMaintenanceTaskMutation();
  const editMaintenanceTaskMutation = useEditMaintenanceTaskMutation();
  const initialMaintenanceTaskId = useRef(maintenanceTaskId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getMaintenanceTaskQuery = initialMaintenanceTaskId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetMaintenanceTaskDetailsQuery(
        {
          id: initialMaintenanceTaskId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getMaintenanceTaskQuery?.data?.maintenanceTaskById) {
      onLoad?.(getMaintenanceTaskQuery.data?.maintenanceTaskById);
    }
  }, [onLoad, getMaintenanceTaskQuery?.data]);

  if (maintenanceTaskId) {
    if (
      getMaintenanceTaskQuery?.isLoading ||
      getMaintenanceTaskQuery?.isFetching
    ) {
      return <LoadingOverlay loading />;
    }
    if (getMaintenanceTaskQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the maintenanceTask.{" "}
          {(getMaintenanceTaskQuery?.error as Error).message || "Unknown error"}
        </Alert>
      );
    }
  }

  const isMileageValid = (maintenanceTask: NewMaintenanceTaskInput) => {
    if (maintenanceTask.startMileage && asset && "mileage" in asset) {
      return (
        maintenanceTask.status !== MaintenanceTaskStatus.Completed &&
        (asset.mileage || asset.mileage === 0)
      );
    }
    return true;
  };

  return (
    <MaintenanceTaskForm
      initialMaintenanceTask={
        getMaintenanceTaskQuery?.data?.maintenanceTaskById
      }
      saving={
        addMaintenanceTaskMutation.isLoading ||
        editMaintenanceTaskMutation.isLoading
      }
      onSave={async (maintenanceTask) => {
        try {
          if (!isMileageValid(maintenanceTask)) {
            dispatch(
              showDialog({
                title: "Error",
                description:
                  "You must set mileage on the asset before setting it on maintenance tasks",
                type: "error",
              })
            );
            return;
          }
          if (maintenanceTaskId) {
            const result = await editMaintenanceTaskMutation.mutateAsync({
              id: maintenanceTaskId,
              editMaintenanceTaskData: omit(maintenanceTask, "_id"),
            });
            onSaved?.({
              ...maintenanceTask,
              _id: result.editMaintenanceTask._id,
            });
            // navigate(`/maintenanceTasks/details/${result.editMaintenanceTask._id}`);
          } else {
            const result = await addMaintenanceTaskMutation.mutateAsync({
              newMaintenanceTaskData: {
                ...maintenanceTask,
              },
            });
            onSaved?.({
              ...maintenanceTask,
              _id: result.addMaintenanceTask._id,
            });
            // navigate(`/maintenanceTasks/details/${result.addMaintenanceTask._id}`);
            // window.analytics?.track("MaintenanceTask Created", {
            //   maintenanceTaskId: result.addMaintenanceTask._id,
            // });
            // window.analytics?.identify({
            //   maintenanceTaskCreated: true,
            //   lastMaintenanceTaskCreationDate: new Date(),
            //   lastMaintenanceTaskCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfMaintenanceTasksCreated:
            //     (window.analytics?.user?.()?.traits?.()?.numberOfMaintenanceTasksCreated ||
            //       0) + 1,
            // });
            // window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            //   maintenanceTaskCreated: true,
            //   lastMaintenanceTaskCreationDate: new Date(),
            //   lastMaintenanceTaskCreationDateOnly: new Date()
            //     .toISOString()
            //     .split("T")[0],
            //   numberOfMaintenanceTasksCreated:
            //     (window.analytics?.group?.()?.traits?.()?.numberOfMaintenanceTasksCreated ||
            //       0) + 1,
            // });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  maintenanceTaskId ? "editing" : "creating"
                } the maintenanceTask. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      {...props}
    />
  );
}

export default MaintenanceTaskFormContainer;

import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetBusinessEntityDetailsQuery,
  useDeleteBusinessEntityMutation,
  useGetBusinessEntityDetailsQuery,
} from "../../../graphql/generated";
import {
  hideDialog,
  showDialog,
} from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import BusinessEntityDetails from "./BusinessEntityDetails";
import { useTranslation } from "react-i18next";

type BusinessEntityDetailsContainerProps = {
  businessEntityId: string;
  urlPrefix: string;
  onLoad?: (
    businessEntity: GetBusinessEntityDetailsQuery["businessEntityById"]
  ) => void;
};

function BusinessEntityDetailsContainer({
  businessEntityId,
  urlPrefix,
  onLoad,
}: BusinessEntityDetailsContainerProps) {
  const { t } = useTranslation(["business", "common"]);
  const query = useGetBusinessEntityDetailsQuery({
    id: businessEntityId,
  });
  const deleteBusinessEntityMutation = useDeleteBusinessEntityMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query.data?.businessEntityById) {
      onLoad?.(query.data.businessEntityById);
    }
  }, [onLoad, query.data]);

  if (query.isLoading) {
    return <LoadingOverlay loading />;
  }
  if (query.error) {
    return (
      <Alert severity="error">
        {t("common:error.fetchError", "An error occurred while fetching")}.{" "}
        {(query.error as Error).message ||
          t("common:error.unknownError", "Unknown error")}
      </Alert>
    );
  }
  if (!query.data.businessEntityById) {
    return (
      <Alert severity="error">{t("common:error.notFound", "Not found")}</Alert>
    );
  }
  return (
    <BusinessEntityDetails
      businessEntity={query.data.businessEntityById}
      onEdit={() => {
        navigate(`${urlPrefix}/${businessEntityId}/edit`);
      }}
      onDelete={async () => {
        dispatch(
          showDialog({
            title: t("common:error.dangerZone", "Danger zone"),
            description: t(
              "error.deleteBusinessConfirmation",
              "Do you really want to delete this business entity? This action cannot be undone."
            ),
            type: "error",
            actions: [
              {
                type: "error",
                title: t("common:error.yesDelete", "Yes, Delete"),
                onClick: async () => {
                  try {
                    await deleteBusinessEntityMutation.mutateAsync({
                      id: businessEntityId,
                    });
                    dispatch(hideDialog());
                    navigate(urlPrefix);
                  } catch (error) {
                    console.error(error);
                    dispatch(
                      showDialog({
                        title: t("common:error.title", "Error"),
                        type: "error",
                        description:
                          t(
                            "error.deleteBusinessError",
                            "An error occurred while deleting the business entity."
                          ) +
                            " " +
                            (error as Error).message ||
                          t("common:error.unknownError", "Unknown error"),
                      })
                    );
                  }
                },
              },
              {
                type: "primary",
                title: t("common:error.noCancel", "No, Cancel"),
              },
            ],
          })
        );
      }}
    />
  );
}

export default BusinessEntityDetailsContainer;

import { Button, Stack } from "@mui/material";
import { ChargeType, TransactionType } from "../../../graphql/generated";
import InfoBlock from "../../common/InfoBlock";
import { useTranslation } from "react-i18next";
import RoleGuard from "../../account/Access/RoleGuard";
import { AttachMoney } from "@mui/icons-material";
import ShipmentTransactionsList from "../../shipment/ShipmentForm/ShipmentTransactionsList";
import { useState } from "react";
import TransactionFormModal from "../../shipment/ShipmentForm/TransactionFormModal";
import ObjectID from "bson-objectid";
import { InvoiceDetailsData } from "./InvoiceDetails";

type InvoiceDetailsChargeTableProps = {
  chargeType: ChargeType;
  charges: NonNullable<InvoiceDetailsData["charges"]>;
  shipments: NonNullable<InvoiceDetailsData["shipments"]>;
  onChange: (charges: InvoiceDetailsData["charges"]) => void;
  readonly?: boolean;
};

const InvoiceDetailsChargeTable: React.FC<InvoiceDetailsChargeTableProps> = (
  props
) => {
  const { t } = useTranslation("finance");
  const [isChargeModalOpen, setChargeModalOpen] = useState(false);

  const [chargeToEdit, setChargeToEdit] =
    useState<InvoiceDetailsData["charges"][0] | null>(null);

  const titlesByChargeType: Record<ChargeType, string> = {
    [ChargeType.Linehaul]: t("lineHaulCharges", "Linehaul Charges"),
    [ChargeType.Accessorial]: t("accessorialCharges", "Accessorial Charges"),
    [ChargeType.Tax]: t("taxes", "Taxes"),
  };

  return (
    <>
      <InfoBlock
        title={titlesByChargeType[props.chargeType]}
        action={
          <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
            <RoleGuard roles={["Carrier Admin", "Manager", "Dispatcher"]}>
              <Button
                variant="outlined"
                onClick={() => {
                  setChargeToEdit(null);
                  setChargeModalOpen(true);
                }}
                startIcon={<AttachMoney />}
                id="add-charge-button"
                disabled={props.readonly}
              >
                {t("addCharge")}
              </Button>
            </RoleGuard>
          </Stack>
        }
      >
        <ShipmentTransactionsList
          transactions={props.charges || []}
          shipments={props.shipments}
          onDelete={(id) => {
            props.onChange(props.charges.filter((c) => c._id !== id));
          }}
          onChange={(charges) => {
            props.onChange(
              props.charges.map((c) => ({
                ...c,
                _id: c._id || new ObjectID().toHexString(),
              }))
            );
          }}
          onEdit={(id) => {
            const charge = props.charges.find((c) => c._id === id);
            if (charge) {
              setChargeToEdit(charge);
              setChargeModalOpen(true);
            }
          }}
          readonly={props.readonly}
          isReimbursable={false}
          id="shipment-charges-list"
        />
      </InfoBlock>

      <TransactionFormModal
        routeDistance={0}
        routeDistanceWithDeadhead={0}
        initialTransaction={chargeToEdit}
        isOpened={isChargeModalOpen}
        onClose={() => {
          setChargeToEdit(null);
          setChargeModalOpen(false);
        }}
        shipments={props.shipments}
        transactionType={TransactionType.Income}
        onSubmit={(charge) => {
          if (chargeToEdit) {
            props.onChange(
              props.charges.map((c) => {
                if (c._id === chargeToEdit._id) {
                  return { ...charge, _id: new ObjectID().toHexString() };
                }
                return c;
              })
            );
          } else {
            props.onChange([
              ...(props.charges || []),
              {
                ...charge,
                _id: new ObjectID().toHexString(),
                chargeType: props.chargeType,
              },
            ]);
          }
          setChargeToEdit(null);
          setChargeModalOpen(false);
        }}
      />
    </>
  );
};

export default InvoiceDetailsChargeTable;

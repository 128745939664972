import { Box, Stack, Typography } from "@mui/material";
import { ReactNode, isValidElement } from "react";

const LabeledInfo = ({
  title,
  icon,
  value,
  onClick,
  centered = false,
}: {
  title: string;
  icon?: ReactNode;
  value?: ReactNode;
  centered?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Stack
      sx={{
        display: "inline-block",
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        textAlign: centered ? "center" : undefined,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: 15,
          display: "flex",
          justifyContent: centered ? "center" : undefined,
          fontWeight: "600",
          textTransform: "capitalize",
        }}
      >
        {icon ? <Box sx={{ mr: 1 }}> {icon} </Box> : <></>} {title}
      </Typography>
      {isValidElement(value) ? (
        value
      ) : (
        <Typography
          variant="body1"
          onClick={onClick}
          sx={{
            textDecoration: onClick ? "underline" : undefined,
            cursor: onClick ? "pointer" : undefined,
            wordBreak: "break-word",
          }}
        >
          {value || "N/A"}
        </Typography>
      )}
    </Stack>
  );
};

export default LabeledInfo;

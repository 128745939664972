import {
  LocationOn,
  ModeEditOutlineOutlined,
  Tag,
  Abc,
  LiveHelp,
  MonitorWeight,
  EmojiTransportation,
  Style,
  GroupWork,
  Speed,
  Delete,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MarkerF } from "@react-google-maps/api";
import { useState } from "react";
import {
  GetTractorDetailsQuery,
  TripAssetTypes,
} from "../../../graphql/generated";
import toLatLng from "../../../utils/geo/toLaLng";
import formatWeight from "../../../utils/labels/formatWeight";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import Map from "../../common/Map/Map";
import { useTranslation } from "react-i18next";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import MaintenanceTasksListContainer from "../../maintenance/MaintenanceTasksList";
import GroupsDisplayContainer from "../GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import { formatMileage } from "../../../utils/labels/formatDistance";
import RoleGuard from "../../account/Access/RoleGuard";
import AssetLinkingsListContainer from "../AssetLinkingsList";

export type TractorDetailsData = GetTractorDetailsQuery["tractorById"];

type TractorDetailsProps = {
  tractor: TractorDetailsData;
  onEdit: () => void;
  onDelete: () => void;
};

function TractorDetails({ tractor, onEdit, onDelete }: TractorDetailsProps) {
  const { t } = useTranslation(["common", "assets"]);
  const [isAddressMapOpen, setIsAddressMapOpen] = useState(false);

  const fromThirdParty = !!tractor.eldId;

  return (
    <Box>
      {fromThirdParty && (
        <Alert severity="info">
          This tractor is managed by a third-party service.
        </Alert>
      )}
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <RoleGuard roles={["Carrier Admin"]}>
          <IconButton onClick={onDelete} color="error">
            <Delete />
          </IconButton>
        </RoleGuard>
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <InfoBlock title={t("assets:tractor.details", "Tractor Details")}>
            <LabeledInfo
              title={t("assets:tractor.code", "Asset Code")}
              value={tractor.serialNumber}
              icon={<Abc />}
            />
            <LabeledInfo
              title="VIN#"
              value={tractor.plateNumber?.toString()}
              icon={<Tag />}
            />
            <LabeledInfo
              title={t("assets:licenseNumber", "License number")}
              value={tractor.licenseNumber?.toString()}
              icon={<Tag />}
            />
            <LabeledInfo
              title={`${t("assets:tractor.make", "Make")}/${t(
                "assets:tractor.model",
                "Model"
              )}/${t("assets:tractor.year", "Year")}`}
              value={
                `${tractor.make || ""} ${tractor.model || ""} ${
                  tractor.year || ""
                }`.trim() || "N/A"
              }
              icon={<EmojiTransportation />}
            />
            <LabeledInfo
              title="Tare Weight"
              value={
                tractor.tareWeight ? formatWeight(tractor.tareWeight) : "N/A"
              }
              icon={<MonitorWeight />}
            />
            <LabeledInfo
              title="Mileage"
              value={tractor.mileage ? formatMileage(tractor.mileage) : "N/A"}
              icon={<Speed />}
            />
            <LabeledInfo
              title={t("assets:status", "Status")}
              value={tractor.status}
              icon={<LiveHelp />}
            />
            <LabeledInfo
              title={t("common:tags")}
              value={<ChipTagsList value={tractor.tags || []} />}
              icon={<Style />}
            />

            <LabeledInfo
              title={t("common:groups")}
              value={<GroupsDisplayContainer groupIds={tractor.groupIds} />}
              icon={<GroupWork />}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={6}>
          <InfoBlock title={t("assets:domicile", "Domicile")}>
            <LabeledInfo
              title={t("assets:address", "Address")}
              value={tractor.domicile?.label}
              icon={<LocationOn />}
              onClick={() => {
                if (tractor.domicile) {
                  setIsAddressMapOpen(true);
                }
              }}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <CustomFieldsDisplayContainer customFields={tractor.customFields} />
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Asset Linking">
            <AssetLinkingsListContainer
              assetType={TripAssetTypes.Tractor}
              assetId={tractor._id}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Maintenance tasks">
            <MaintenanceTasksListContainer
              asset={tractor}
              assetType={TripAssetTypes.Tractor}
              assetId={tractor._id}
            />
          </InfoBlock>
        </Grid>

        <Grid item sm={12}>
          <InfoBlock title={t("common:documents.many")}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("common:name")}</TableCell>
                  <TableCell
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {t("common:documents.one")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tractor.documents?.map((document) => (
                  <TableRow>
                    <TableCell>{document.name}</TableCell>
                    <TableCell>
                      <Link href={document.url} component="a" target="_blank">
                        Open
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfoBlock>
        </Grid>
      </Grid>
      {tractor.domicile && (
        <Dialog
          open={isAddressMapOpen}
          onClose={() => setIsAddressMapOpen(false)}
        >
          <DialogTitle>{tractor.domicile?.label}</DialogTitle>

          <DialogContent>
            <Map center={toLatLng(tractor?.domicile?.coordinates)} zoom={13}>
              <MarkerF position={toLatLng(tractor?.domicile?.coordinates)} />
            </Map>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setIsAddressMapOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default TractorDetails;

import { useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  AssetStatus,
  BusinessEntity,
  BusinessEntityType,
} from "../../../graphql/generated";
import { capitalize, sortBy } from "lodash";
import BusinessEntityFormContainer from "../../asset-management/BusinessEntityForm";
import enumLabel from "../../../utils/labels/enumLabel";
import { useTranslation } from "react-i18next";
import businessEntityLabelKey from "../../../utils/i18n/businessEntityLabelKey";

enum AutocompleteOptionType {
  BusinessEntity = "BusinessEntity",
  Address = "Address",
}

type AutocompleteOption = {
  id: string;
  label: string;
  type: AutocompleteOptionType;
  status: AssetStatus;
};

const filter = createFilterOptions<AutocompleteOption>();

type SelectBusinessEntity = Pick<
  BusinessEntity,
  | "name"
  | "_id"
  | "address"
  | "openingSchedules"
  | "code"
  | "mcNumber"
  | "type"
  | "additionalTypes"
  | "status"
>;

export type BusinessEntitySelectProps = Omit<
  AutocompleteProps<string, true, undefined, undefined>,
  | "options"
  | "disablePortal"
  | "filterOptions"
  | "getOptionLabel"
  | "size"
  | "renderInput"
  | "onChange"
> & {
  businessEntityType: BusinessEntityType;
  businessEntities?: Array<SelectBusinessEntity>;
  onBusinessEntitiesRefetch: () => Promise<void>;
  onChange: (businessEntityIds: string[] | null) => void;
  inputProps?: TextFieldProps;
};

export default function BusinessEntitySelect({
  businessEntityType,
  businessEntities,
  onBusinessEntitiesRefetch,
  inputProps: providedInputProps,
  onChange,
  ...props
}: BusinessEntitySelectProps) {
  const { t } = useTranslation(["business", "common"]);
  const [isNewBusinessEntityModalOpen, setIsNewBusinessEntityModalOpen] =
    useState(false);

  const getBeOptionName = (businessEntityId: string) => {
    if (businessEntityId === "NEW") {
      return t("addNewBusiness", {
        business: businessEntityLabelKey[businessEntityType],
        defaultValue: `Add new ${enumLabel(businessEntityType)}`,
      });
    }
    const businessEntity = businessEntities?.find(
      (be) => be._id === businessEntityId
    );
    if (!businessEntity) {
      return "";
    }
    const allTypes = [
      businessEntity.type,
      ...(businessEntity.additionalTypes || []),
    ];
    const isBroker = allTypes.includes(BusinessEntityType.Broker);
    return `${businessEntity.code ? `${businessEntity.code} - ` : ""}${
      isBroker && businessEntity.mcNumber ? `${businessEntity.mcNumber} - ` : ""
    }${businessEntity.name}`;
  };

  const rolodexAutocompleteOptions = sortBy(
    businessEntities || [],
    (be) => be.name
  ).map((be) => ({
    id: be._id,
    label: getBeOptionName(be._id),
    type: AutocompleteOptionType.BusinessEntity,
    status: be.status,
  }));

  return (
    <>
      <Autocomplete
        options={rolodexAutocompleteOptions}
        value={rolodexAutocompleteOptions.filter(
          (option) => props.value?.includes(option.id) || false
        )}
        multiple={props.multiple}
        getOptionLabel={(option) => {
          return option.status === AssetStatus.Inactive
            ? `${option.label} (${t("common:statusTypes.INACTIVE")})`
            : option.label;
        }}
        getOptionDisabled={(option) => option.status === AssetStatus.Inactive}
        filterOptions={(options, params) => {
          const filtered = filter(
            options.filter(
              (option) => option.type === AutocompleteOptionType.BusinessEntity
            ),
            params
          );
          return filtered;
        }}
        onChange={(event, newValue) => {
          onChange(newValue.map((option) => option.id));
        }}
        size="small"
        renderInput={(inputProps) => (
          <TextField
            name={enumLabel(businessEntityType)?.toLowerCase() || ""}
            required
            {...inputProps}
            {...providedInputProps}
            label={capitalize(
              t("entityLabel", {
                business: businessEntityLabelKey[businessEntityType],
                defaultValue: enumLabel(businessEntityType),
              })
            )}
          />
        )}
        fullWidth
      />
      <Dialog
        open={isNewBusinessEntityModalOpen}
        onClose={() => {
          setIsNewBusinessEntityModalOpen(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>
          {capitalize(
            t("newBusiness", {
              business: businessEntityLabelKey[businessEntityType],
              defaultValue: `New ${enumLabel(businessEntityType)}`,
            })
          )}
        </DialogTitle>
        <DialogContent>
          <BusinessEntityFormContainer
            defaultType={businessEntityType}
            navigateAfterSave={false}
            onSaved={(businessEntity) => {
              setIsNewBusinessEntityModalOpen(false);
              onBusinessEntitiesRefetch();
              onChange(
                // @ts-ignore
                props.multiple
                  ? props.value?.concat(businessEntity._id)
                  : businessEntity._id
              );
            }}
            minimal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

import {
  LocationOn,
  ModeEditOutlineOutlined,
  LiveHelp,
  Call,
  Person,
  PermContactCalendar,
  FileUpload,
  Email,
  AdminPanelSettings,
  Style,
  GroupWork,
  RemoveModerator,
  LockOpen,
  LockOutlined,
  Delete,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MarkerF } from "@react-google-maps/api";
import { useState } from "react";
import {
  GetDriverDetailsQuery,
  GetOrgUserDetailsQuery,
  TripAssetTypes,
} from "../../../graphql/generated";
import toLatLng from "../../../utils/geo/toLaLng";
import formatPhoneNumber from "../../../utils/labels/formatPhoneNumber";
import InfoBlock from "../../common/InfoBlock";
import LabeledInfo from "../../common/LabeledInfo";
import Map from "../../common/Map/Map";
import driverLabel from "../../../utils/labels/driverLabel";
import { formatDate } from "../../../utils/labels/formatDateTime";
import DriverDocumentsTable from "./DriverDocumentsTable";
import DocumentsUploadModal, {
  Document,
} from "../../common/DocumentsUploadModal";
import { capitalize, without } from "lodash";
import useDialog from "../../../utils/hooks/useDialog";
import enumLabel from "../../../utils/labels/enumLabel";
import { formatAddress } from "../../../utils/labels/formatAddress";
import AssetLinkingFormModal from "../AssetLinkingFormModal";
import { LoadingButton } from "@mui/lab";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverLicenseExpiryWarning from "../DriverLicenseExpiryWarning/DriverLicenseExpiryWarning";
import { useTranslation } from "react-i18next";
import ChipTagsList from "../../common/ChipTagsList/ChipTagsList";
import MaintenanceTasksListContainer from "../../maintenance/MaintenanceTasksList";
import GroupsDisplayContainer from "../GroupsDisplay";
import CustomFieldsDisplayContainer from "../../custom-field-definitions/CustomFieldsDisplay";
import QualificationsListContainer from "../../qualifications/QualificationsList";
import AssetLinkingsListContainer from "../AssetLinkingsList";
import DriverShiftAssignmentsListContainer from "../../driver-shifts/DriverShiftAssignmentsList";
import RoleGuard from "../../account/Access/RoleGuard";

export type DriverDetailsData = GetDriverDetailsQuery["driverById"];
type UserDetailsData = GetOrgUserDetailsQuery["orgUserById"];

type DriverDetailsProps = {
  driver: DriverDetailsData;
  user: UserDetailsData | null;
  onEdit: () => void;
  onDelete: () => void;
  onMakeAdmin: (() => void) | null;
  onRevokeAdmin: (() => void) | null;
  onDocumentsUpdate: (document: Array<Document>) => void;
  updatingPassword: boolean;
  onPasswordUpdate: ((password: string) => void) | null;
  onUserAccountStatusUpdate: (enabled: boolean) => void;
};

function DriverDetails({
  driver,
  user,
  onEdit,
  onDelete,
  onMakeAdmin,
  onRevokeAdmin,
  onDocumentsUpdate,
  onPasswordUpdate,
  updatingPassword,
  onUserAccountStatusUpdate,
}: DriverDetailsProps) {
  const { t } = useTranslation(["users", "common"]);
  const [isAddressMapOpen, setIsAddressMapOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isLinkingModalOpen, setIsLinkingModalOpen] = useState(false);
  const { showDialog, hideDialog } = useDialog();
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");

  const fromThirdParty = !!driver.eldId;

  return (
    <Box>
      {fromThirdParty && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {t(
            "driver.thirdPartyDriver",
            "This driver is managed by a third-party service."
          )}
        </Alert>
      )}
      {user && !user.enabled && (
        <Alert severity="error" sx={{ mb: 2 }}>
          <Typography variant="body2" color="error">
            {t(
              "disabledDesc",
              "This user disabled. He cannot login to his account."
            )}
          </Typography>
        </Alert>
      )}
      <Stack direction="row" flexDirection="row-reverse" spacing={2}>
        <RoleGuard roles={["Carrier Admin"]}>
          <IconButton onClick={onDelete} color="error">
            <Delete />
          </IconButton>
        </RoleGuard>
        {user && (
          <IconButton
            onClick={() => onUserAccountStatusUpdate(!user.enabled)}
            color={user.enabled ? "error" : "success"}
          >
            <Tooltip
              title={t(
                user.enabled ? "disable" : "enable",
                user.enabled ? "Disable" : "Enable"
              )}
            >
              {user.enabled ? <LockOutlined /> : <LockOpen />}
            </Tooltip>
          </IconButton>
        )}
        <IconButton onClick={onEdit} color="primary">
          <ModeEditOutlineOutlined />
        </IconButton>
        {onMakeAdmin && (
          <Tooltip title={t("driver.makeAdmin", "Make admin")}>
            <IconButton onClick={onMakeAdmin} color="primary">
              <AdminPanelSettings />
            </IconButton>
          </Tooltip>
        )}
        {onRevokeAdmin && (
          <Tooltip title={t("driver.revokeAdmin", "Revoke admin")}>
            <IconButton onClick={onRevokeAdmin} color="primary">
              <RemoveModerator />
            </IconButton>
          </Tooltip>
        )}
        {onPasswordUpdate && (
          <LoadingButton
            variant="outlined"
            loading={updatingPassword}
            disabled={updatingPassword}
            onClick={() => {
              setIsUpdatePasswordModalOpen(true);
            }}
          >
            {t("resetPassword", "Reset password")}
          </LoadingButton>
        )}
      </Stack>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <InfoBlock
            title={capitalize(t("users:driver.details", "Driver Details"))}
          >
            <LabeledInfo
              title={t("users:driver.name", "Name")}
              value={driverLabel(driver)}
              icon={<Person />}
            />
            <LabeledInfo
              title={t("users:phoneNumber", "Phone number")}
              value={formatPhoneNumber(driver.phoneNumber)}
              icon={<Call />}
            />
            <LabeledInfo
              title={t("users:driver.dateOfBirth", "Date of birth")}
              value={
                driver.dateOfBirth
                  ? formatDate(new Date(driver.dateOfBirth), null, false)
                  : "N/A"
              }
              icon={<PermContactCalendar />}
            />
            <LabeledInfo
              title={t("users:driver.status.one", "Status")}
              value={t(`common:statusTypes.${driver.status}`, driver.status)}
              icon={<LiveHelp />}
            />
            <LabeledInfo
              title={t("users:driver.domicile", "Domicile")}
              value={driver.domicileEntity?.name}
              icon={<LocationOn />}
              onClick={() => {
                if (driver.domicileEntity) {
                  setIsAddressMapOpen(true);
                }
              }}
            />
            <LabeledInfo
              title={t("users:driver.isOwnerOperator", "Is Owner operator")}
              value={
                driver.isOwnerOperator
                  ? t("common:yes", "Yes")
                  : t("common:no", "No")
              }
              icon={<LiveHelp />}
            />

            <LabeledInfo
              title={t("common:tags")}
              value={<ChipTagsList value={driver.tags || []} />}
              icon={<Style />}
            />

            <LabeledInfo
              title={t("common:groups")}
              value={<GroupsDisplayContainer groupIds={driver.groupIds} />}
              icon={<GroupWork />}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={6}>
          <InfoBlock
            title={capitalize(t("users:driver.license.one", "License"))}
          >
            <LabeledInfo
              title={capitalize(t("users:driver.license.number", "Number"))}
              value={driver.license?.licenseNumber}
            />
            <LabeledInfo
              title={t("common:address.state", "State")}
              value={driver.license?.issuedState}
            />
            <LabeledInfo
              title={capitalize(t("users:driver.issuedDate", "Issued Date"))}
              value={formatDate(driver.license?.issuedDate, null, false)}
            />
            <LabeledInfo
              title={t("users:driver.expiryDate", "Expiry Date")}
              value={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>
                    {formatDate(driver.license?.expiryDate, null, false)}
                  </Typography>
                  <DriverLicenseExpiryWarning driver={driver} />
                </Stack>
              }
            />
            <LabeledInfo
              title={t("users:driver.classification", "Classification")}
              value={enumLabel(driver.license?.classification)}
            />
            <LabeledInfo
              title={t("users:driver.endorsements", "Endorsements")}
              value={driver.license?.endorsements?.map(enumLabel).join(", ")}
            />
            <LabeledInfo
              title={t("users:driver.restriction", "Restriction")}
              value={enumLabel(driver.license?.restriction)}
            />
            <LabeledInfo
              title={t("users:address", "Address")}
              value={formatAddress(driver.license?.address)}
            />
          </InfoBlock>
        </Grid>
        <Grid item sm={6}>
          <InfoBlock title={t("users:driver.medicalDetails", "Medical")}>
            <LabeledInfo
              title={t("users:driver.medicalNumber", "Medical Number")}
              value={driver.medical?.number}
            />
            <LabeledInfo
              title={t("users:driver.issuedDate", "Issued Date")}
              value={formatDate(driver.medical?.issuedDate, null, false)}
            />
            <LabeledInfo
              title={t("users:driver.expiryDate", "Expiry Date")}
              value={formatDate(driver.medical?.expiryDate, null, false)}
            />
            <LabeledInfo
              title={t("users:driver.restriction", "Restriction")}
              value={driver.medical?.restriction}
            />
          </InfoBlock>
        </Grid>

        {user ? (
          <Grid item sm={12}>
            <InfoBlock title={t("users:details", "User Details")}>
              <LabeledInfo
                title={t("users:firstName", "First name")}
                value={user.firstName}
                icon={<Person />}
              />
              <LabeledInfo
                title={t("users:lastName", "Last name")}
                value={user.lastName}
                icon={<Person />}
              />
              <LabeledInfo
                title={t("users:email", "Email")}
                value={user.email}
                icon={<Email />}
              />
            </InfoBlock>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <CustomFieldsDisplayContainer customFields={driver.customFields} />
        </Grid>

        <Grid item xs={12}>
          <InfoBlock
            title={capitalize(t("users:driver.document.many", "Documents"))}
            action={
              <Button
                variant="outlined"
                onClick={() => setIsDocumentModalOpen(true)}
                startIcon={<FileUpload />}
              >
                {t("users:driver.document.uploadOne", "Upload Document")}
              </Button>
            }
          >
            <DriverDocumentsTable
              documents={driver.documents || []}
              onDelete={(id) => {
                const document = driver.documents?.find(
                  (doc) => doc._id === id
                );
                if (!document) {
                  return;
                }
                showDialog({
                  type: "primary",
                  title: t("common:documents.delete", "Delete document"),
                  description: t("common:documents.deleteConfirmation", {
                    documentName: document.name,
                    defaultValue: `Are you sure you want to delete document ${document.name} ?`,
                  }),
                  actions: [
                    {
                      type: "error",
                      title: t("common:error.yesDelete", "Yes, delete"),
                      onClick: () => {
                        onDocumentsUpdate(without(driver.documents, document));
                        hideDialog();
                      },
                    },
                    {
                      type: "primary",
                      title: t("common:error.noCancel", "No, cancel"),
                    },
                  ],
                });
              }}
            />
          </InfoBlock>
        </Grid>
        <DocumentsUploadModal
          isOpen={isDocumentModalOpen}
          onSubmit={(addedDocuments) => {
            setIsDocumentModalOpen(false);
            onDocumentsUpdate([...(driver.documents || []), ...addedDocuments]);
          }}
          onCancel={() => {
            setIsDocumentModalOpen(false);
          }}
        />

        <Grid item xs={12}>
          <InfoBlock title="Asset Linking">
            <AssetLinkingsListContainer
              assetType={TripAssetTypes.Driver}
              assetId={driver._id}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Shift Assignments">
            <DriverShiftAssignmentsListContainer driverId={driver._id} />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Maintenance tasks">
            <MaintenanceTasksListContainer
              assetType={TripAssetTypes.Driver}
              assetId={driver._id}
            />
          </InfoBlock>
        </Grid>

        <Grid item xs={12}>
          <InfoBlock title="Qualifications">
            <QualificationsListContainer
              assetType={TripAssetTypes.Driver}
              assetId={driver._id}
            />
          </InfoBlock>
        </Grid>
      </Grid>

      {driver.domicileEntity && (
        <Dialog
          open={isAddressMapOpen}
          onClose={() => setIsAddressMapOpen(false)}
        >
          <DialogTitle>{driver.domicileEntity?.name}</DialogTitle>

          <DialogContent>
            <Map
              center={toLatLng(driver?.domicileEntity?.address.coordinates)}
              zoom={13}
            >
              <MarkerF
                position={toLatLng(driver?.domicileEntity.address.coordinates)}
              />
            </Map>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setIsAddressMapOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <AssetLinkingFormModal
        isOpen={isLinkingModalOpen}
        onClose={() => {
          setIsLinkingModalOpen(false);
        }}
        selectedDriverId={driver._id}
      />

      <Dialog
        open={isUpdatePasswordModalOpen}
        onClose={() => setIsUpdatePasswordModalOpen(false)}
      >
        <LoadingOverlay loading={updatingPassword} />
        <DialogTitle>{t("updatePassword", "Update user password")}</DialogTitle>
        <DialogContent dividers>
          <TextField
            label={t("password", "Password")}
            type="password"
            name="password"
            required
            fullWidth
            helperText={t(
              "passwordResetDescription",
              "User will need to change his password on his next login"
            )}
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              onPasswordUpdate?.(newPassword);
              setIsUpdatePasswordModalOpen(false);
              setNewPassword("");
            }}
          >
            {t("update", "Update")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DriverDetails;

import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Address,
  BusinessEntityType,
  GoodInput,
  GoodUnits,
  Receiver,
  ShipmentLocationType,
  Shipper,
} from "../../../../graphql/generated";
import {
  FormError,
  ShipmentLocationInputData,
} from "../../../../redux/slices/Types";
import _, { keyBy } from "lodash";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import {
  endOfDay,
  getDay,
  isValid,
  isWithinInterval,
  parse,
  startOfDay,
} from "date-fns";
import formatWeight from "../../../../utils/labels/formatWeight";
import BusinessEntitySelectContainer from "../BusinessEntitySelect";
import goodUnitLabel from "../../../../utils/labels/goodUnitsLabel";
import { SelectBusinessEntity } from "../BusinessEntitySelect/BusinessEntitySelect";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { fallbackTimezone } from "../../../../utils/labels/formatDateTime";
import { isPickupLocation } from "../../../../utils/location/isPickupLocation";
import { useTranslation } from "react-i18next";

type ShipperOrReceiver =
  | Pick<
      Shipper,
      | "name"
      | "_id"
      | "address"
      | "addressTimezone"
      | "openingSchedules"
      | "code"
      | "type"
      | "status"
    >
  | Pick<
      Receiver,
      | "name"
      | "_id"
      | "address"
      | "addressTimezone"
      | "openingSchedules"
      | "code"
      | "type"
      | "status"
    >;

export interface ShipmentLocationFormProps {
  locationType: ShipmentLocationType;
  businessEntities?: Array<ShipperOrReceiver>;
  fetchBusinessEntity: (id: string) => Promise<ShipperOrReceiver | null>;
  shippedGoods: Array<GoodInput>;
  data: ShipmentLocationInputData;
  initialLocationId?: string;
  locationOnly?: boolean;
  noTimeWindows?: boolean;
  renderAfterLocationSelect?: () => React.ReactNode;
  onChange: (data: ShipmentLocationInputData) => void;
  onGoodsOpen: () => void;
  errors?: FormError;
  filterBusinessEntityIds?: Array<string>;
  allowAddressLocation?: boolean;
  allowNewLocation?: boolean;
}

export default function ShipmentLocationForm({
  locationType,
  businessEntities,
  fetchBusinessEntity,
  shippedGoods,
  data,
  locationOnly = false,
  noTimeWindows = false,
  errors,
  filterBusinessEntityIds,
  onChange,
  onGoodsOpen,
  renderAfterLocationSelect,
  allowAddressLocation = true,
  initialLocationId,
  allowNewLocation,
}: ShipmentLocationFormProps) {
  const { t } = useTranslation(["orders"]);
  const [state, setState] = useState<ShipmentLocationInputData>(data);
  const [formErrors, setFormErrors] = useState<FormError | undefined>(errors);
  const allShippedGoodsById = keyBy(shippedGoods, "_id");

  const [processingUpdate, setProcessingUpdate] = useState(false);
  const fromDate = state.timeWindows[0]?.fromDate
    ? new Date(state.timeWindows[0]?.fromDate)
    : null;
  const toDate = state.timeWindows[0]?.toDate
    ? new Date(state.timeWindows[0]?.toDate)
    : null;

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  useEffect(() => {
    setState(data);
  }, [data]);

  const patchData = (data: Partial<ShipmentLocationInputData>) => {
    setState({ ...state, ...data });
    onChange({ ...state, ...data });
  };

  const handleAutoCompleteChange = async (
    businessEntityId: string | null,
    businessEntity?: SelectBusinessEntity | null
  ) => {
    try {
      setProcessingUpdate(true);
      const err: FormError = {
        formId: formErrors?.formId || "",
        error: _.omit(formErrors?.error, "name"),
      };

      setFormErrors(err);

      const convertedTimeWindows = state.timeWindows.map((tw) => ({
        fromDate: zonedTimeToUtc(
          utcToZonedTime(
            tw.fromDate,
            state.addressTimezone || fallbackTimezone
          ),
          businessEntity?.addressTimezone || fallbackTimezone
        ),
        toDate: zonedTimeToUtc(
          utcToZonedTime(tw.toDate, state.addressTimezone || fallbackTimezone),
          businessEntity?.addressTimezone || fallbackTimezone
        ),
      }));

      patchData({
        shipper:
          locationType === ShipmentLocationType.Pickup ? businessEntityId : "",
        receiver:
          locationType === ShipmentLocationType.DropOff ? businessEntityId : "",
        location: businessEntity?.address.coordinates || {
          latitude: 0,
          longitude: 0,
        },
        name: businessEntity?.name || "",
        addressLabel: businessEntity?.address.label || "",
        addressTimezone: businessEntity?.addressTimezone || "",
        timeWindows: convertedTimeWindows,
      });

      const businessEntityDetails = businessEntityId
        ? await fetchBusinessEntity(businessEntityId)
        : null;

      const { openingSchedule, closingSchedule } =
        getBusinessEntityOpeningSchedules(businessEntityDetails);

      const fromDateFromSchedule = openingSchedule
        ? zonedTimeToUtc(
            parse(openingSchedule, "HH:mm", fromDate || new Date()),
            businessEntityDetails?.addressTimezone || fallbackTimezone
          )
        : state.timeWindows[0]?.fromDate;
      const toDateFromSchedule = closingSchedule
        ? zonedTimeToUtc(
            parse(closingSchedule, "HH:mm", toDate || new Date()),
            businessEntityDetails?.addressTimezone || fallbackTimezone
          )
        : state.timeWindows[0]?.toDate;

      patchData({
        shipper:
          locationType === ShipmentLocationType.Pickup ? businessEntityId : "",
        receiver:
          locationType === ShipmentLocationType.DropOff ? businessEntityId : "",
        location: businessEntityDetails?.address.coordinates || {
          latitude: 0,
          longitude: 0,
        },
        name: businessEntityDetails?.name || "",
        addressLabel: businessEntityDetails?.address.label || "",
        addressTimezone: businessEntity?.addressTimezone || "",
        timeWindows:
          openingSchedule || closingSchedule
            ? [
                {
                  fromDate: fromDateFromSchedule,
                  toDate: toDateFromSchedule,
                },
              ]
            : convertedTimeWindows,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setProcessingUpdate(false);
    }
  };

  useEffect(() => {
    if (!initialLocationId) {
      return;
    }
    const location = businessEntities?.find((s) => s._id === initialLocationId);
    if (location) {
      handleAutoCompleteChange(initialLocationId, location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddressChange = async (address: Address | null) => {
    const err: FormError = {
      formId: formErrors?.formId || "",
      error: _.omit(formErrors?.error, "name"),
    };

    setFormErrors(err);

    patchData({
      location: address?.coordinates,
      addressLabel: address?.label,
      name: "",
      shipper: null,
      receiver: null,
    });
  };

  // Reset local goods error when shipped goods or received goods change
  useEffect(() => {
    setFormErrors((prevFormErrors) => ({
      formId: prevFormErrors?.formId || "",
      error: { ..._.omit(prevFormErrors?.error, "goods") },
    }));
  }, [data.shippedGoods, data.receivedGoods]);

  const handleOpen = () => onGoodsOpen();

  const getSelectedBusinessEntity = () => {
    return businessEntities?.find(
      (s) =>
        (locationType === ShipmentLocationType.Pickup &&
          s._id === data.shipper) ||
        (locationType === ShipmentLocationType.DropOff &&
          s._id === data.receiver)
    );
  };

  const getBusinessEntityOpeningSchedules = (
    businessEntity:
      | ShipperOrReceiver
      | null
      | undefined = getSelectedBusinessEntity(),
    date: Date | string = fromDate || new Date()
  ) => {
    // validate business opening schedule
    const day = getDay(
      zonedTimeToUtc(
        new Date(date),
        state.addressTimezone || fallbackTimezone
      ) || new Date()
    );

    const businessEntityOpeningSchedules =
      businessEntity?.openingSchedules?.find((op) => op.days.includes(day));
    const openingSchedule = businessEntityOpeningSchedules?.openingTime || "";
    const closingSchedule = businessEntityOpeningSchedules?.closingTime || "";

    return { openingSchedule, closingSchedule };
  };

  const isWithinTimeSchedule = (time: string) => {
    try {
      const businessEntity = getSelectedBusinessEntity();
      if (!businessEntity) {
        return true;
      }

      const { openingSchedule, closingSchedule } =
        getBusinessEntityOpeningSchedules();

      const openingTime = parse(openingSchedule, "HH:mm", new Date());
      const closingTime = parse(closingSchedule, "HH:mm", new Date());

      const _date = parse(time, "HH:mm", new Date());

      if (!(isValid(openingTime) && isValid(closingTime) && isValid(_date)))
        return true;

      return isWithinInterval(_date, { start: openingTime, end: closingTime });
    } catch (error) {
      console.error(error);
      return true;
    }
  };

  return (
    <Stack component="form" spacing={3}>
      <BusinessEntitySelectContainer
        businessEntityType={
          locationType === ShipmentLocationType.Pickup
            ? BusinessEntityType.Shipper
            : BusinessEntityType.Receiver
        }
        onChange={handleAutoCompleteChange}
        onAddressSelect={handleAddressChange}
        initialAddressLabel={
          data.shipper || data.receiver ? null : data.addressLabel
        }
        value={data.shipper || data.receiver || null}
        allowAddress={allowAddressLocation}
        inputProps={{
          error: formErrors && formErrors.error["name"] ? true : false,
          helperText:
            formErrors && formErrors.error["name"] !== undefined
              ? formErrors.error["name"]
              : getSelectedBusinessEntity()?.address?.label || "",
        }}
        filterBusinessEntityIds={filterBusinessEntityIds}
        allowNew={allowNewLocation}
      />
      {renderAfterLocationSelect ? renderAfterLocationSelect() : null}
      {noTimeWindows ? null : (
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          spacing={5}
        >
          <Stack flex={1}>
            <InputLabel>
              {isPickupLocation({ locationType })
                ? t("pickupTimeStart", "Pickup time start")
                : t("deliveryTimeStart", "Delivery time start")}
            </InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DatePicker
                  value={
                    fromDate
                      ? utcToZonedTime(
                          fromDate,
                          state.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formErrors &&
                        (formErrors.error["fromDate"] ||
                          formErrors?.error["date"])
                      }
                      error={
                        formErrors &&
                        (formErrors.error["fromDate"] ||
                          formErrors?.error["date"])
                          ? true
                          : false
                      }
                      id={`${locationType}-date`}
                      name={`${locationType}Date`}
                      required={true}
                      size={"small"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    if (!isValid(date)) {
                      return;
                    }
                    patchData({
                      timeWindows: [
                        {
                          fromDate: zonedTimeToUtc(
                            date,
                            state.addressTimezone || fallbackTimezone
                          ),
                          toDate:
                            toDate ||
                            zonedTimeToUtc(
                              endOfDay(date),
                              state.addressTimezone || fallbackTimezone
                            ),
                        },
                      ],
                    });
                    setFormErrors((prevFormErrors) => ({
                      formId: prevFormErrors?.formId || "",
                      error: _.omit(prevFormErrors?.error, "date"),
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TimePicker
                  ampm={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formErrors && formErrors.error["timeStart"]
                          ? true
                          : false
                      }
                      helperText={formErrors && formErrors.error["timeStart"]}
                      size="small"
                      id={`${locationType}-time-start`}
                      name={`${locationType}TimeStart`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                  value={
                    fromDate
                      ? utcToZonedTime(
                          fromDate,
                          state.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    if (!isValid(date)) {
                      return;
                    }
                    patchData({
                      timeWindows: [
                        {
                          fromDate: zonedTimeToUtc(
                            date,
                            state.addressTimezone || fallbackTimezone
                          ),
                          toDate:
                            toDate ||
                            zonedTimeToUtc(
                              endOfDay(date),
                              state.addressTimezone || fallbackTimezone
                            ),
                        },
                      ],
                    });
                    setFormErrors((prevFormErrors) => ({
                      formId: prevFormErrors?.formId || "",
                      error: _.omit(prevFormErrors?.error, "date"),
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {!processingUpdate &&
              fromDate &&
              !isWithinTimeSchedule(
                formatInTimeZone(
                  fromDate,
                  state.addressTimezone || fallbackTimezone,
                  "HH:mm"
                )
              ) && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                  This time is not within the schedule{" "}
                  {getBusinessEntityOpeningSchedules()?.openingSchedule} -{" "}
                  {getBusinessEntityOpeningSchedules()?.closingSchedule}
                </Alert>
              )}
          </Stack>
          <Stack flex={1}>
            <InputLabel>
              {isPickupLocation({ locationType })
                ? t("pickupTimeEnd", "Pickup time end")
                : t("deliveryTimeEnd", "Delivery time end")}
            </InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DatePicker
                  value={
                    toDate
                      ? utcToZonedTime(
                          toDate,
                          state.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formErrors &&
                        (formErrors.error["toDate"] ||
                          formErrors?.error["date"])
                      }
                      error={
                        formErrors &&
                        (formErrors.error["toDate"] ||
                          formErrors?.error["date"])
                          ? true
                          : false
                      }
                      id={`${locationType}-date`}
                      name={`${locationType}Date`}
                      required={true}
                      size={"small"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    if (!isValid(date)) {
                      return;
                    }
                    patchData({
                      timeWindows: [
                        {
                          toDate: zonedTimeToUtc(
                            date,
                            state.addressTimezone || fallbackTimezone
                          ),
                          fromDate:
                            fromDate ||
                            zonedTimeToUtc(
                              startOfDay(date),
                              state.addressTimezone || fallbackTimezone
                            ),
                        },
                      ],
                    });
                    setFormErrors((prevFormErrors) => ({
                      formId: prevFormErrors?.formId || "",
                      error: _.omit(prevFormErrors?.error, "date"),
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TimePicker
                  ampm={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        formErrors && formErrors.error["timeEnd"] ? true : false
                      }
                      helperText={formErrors && formErrors.error["timeEnd"]}
                      size="small"
                      id={`${locationType}-time-end`}
                      name={`${locationType}TimeEnd`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )}
                  value={
                    toDate
                      ? utcToZonedTime(
                          toDate,
                          state.addressTimezone || fallbackTimezone
                        )
                      : null
                  }
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    if (!isValid(date)) {
                      return;
                    }
                    patchData({
                      timeWindows: [
                        {
                          toDate: zonedTimeToUtc(
                            date,
                            state.addressTimezone || fallbackTimezone
                          ),
                          fromDate:
                            fromDate ||
                            zonedTimeToUtc(
                              startOfDay(date),
                              state.addressTimezone || fallbackTimezone
                            ),
                        },
                      ],
                    });
                    setFormErrors((prevFormErrors) => ({
                      formId: prevFormErrors?.formId || "",
                      error: _.omit(prevFormErrors?.error, "date"),
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {!processingUpdate &&
              toDate &&
              !isWithinTimeSchedule(
                formatInTimeZone(
                  toDate,
                  state.addressTimezone || fallbackTimezone,
                  "HH:mm"
                )
              ) && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                  This time is not within the schedule{" "}
                  {getBusinessEntityOpeningSchedules()?.openingSchedule} -{" "}
                  {getBusinessEntityOpeningSchedules()?.closingSchedule}
                </Alert>
              )}
          </Stack>
        </Stack>
      )}
      {locationOnly ? null : (
        <Box sx={{ width: "100%" }}>
          <Button
            variant="contained"
            color={
              locationType === ShipmentLocationType.Pickup
                ? "lightPrimary"
                : "accent"
            }
            onClick={handleOpen}
            fullWidth
            size="medium"
            id={`${locationType}-add-goods-button`}
            className={`${locationType}-add-goods-buttons`}
          >
            {locationType === ShipmentLocationType.Pickup
              ? t("commoditiesToShip", "Commodities to ship")
              : t("commoditiesToReceive", "Commodities to receive")}
          </Button>
          <Box sx={{ mt: 1 }}>
            {(data.shippedGoods || []).map((shippedGood, index) => (
              <Typography key={shippedGood._id} variant="caption">
                {index !== 0 && ", "}
                {shippedGood.label} ({shippedGood.quantity}{" "}
                {goodUnitLabel(shippedGood.unit || GoodUnits.Item)} x{" "}
                {formatWeight(shippedGood.weight)})
              </Typography>
            ))}

            {(data.receivedGoods || []).map((receivedGood, index) => {
              const shippedGood = allShippedGoodsById[receivedGood.goodId];
              if (!shippedGood) {
                return null;
              }
              return (
                <Typography key={receivedGood.goodId} variant="caption">
                  {index !== 0 && ", "}
                  {shippedGood.label} (
                  {receivedGood.quantity || shippedGood.quantity}{" "}
                  {goodUnitLabel(shippedGood.unit || GoodUnits.Item)} x{" "}
                  {formatWeight(shippedGood.weight)})
                </Typography>
              );
            })}
          </Box>
        </Box>
      )}
      {formErrors && formErrors.error["goods"] ? (
        <Alert severity="error">{formErrors.error["goods"]}</Alert>
      ) : null}
    </Stack>
  );
}

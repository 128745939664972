import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField/TextField";
import {
  LoadType,
  NewShipmentInput,
  Shipment,
  ShipmentCommodityType,
  ShipmentConstraint,
  ShipmentConstraintInput,
  ShipmentConstraintType,
  TrailerType,
} from "../../../../graphql/generated";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { identity, isNaN, sortBy, without } from "lodash";
import trailerTypeLabel from "../../../../utils/labels/trailerTypeLabel";
import LocaleProvider from "../../../../providers/LocaleProvider";
import formatTemperature from "../../../../utils/labels/formatTemperature";
import { localTemperatureToCelsius } from "../../../../utils/conversion/temperature";
import trailerRequirementsSchema from "./trailerRequirementSchema";
import { ValidationResult } from "joi";
import getFieldError from "../../../../utils/form/getFieldError";
import DistanceInput from "../../../common/DistanceInput";
import {
  FieldErrors,
  NewShipmentInputData,
} from "../../../../redux/slices/Types";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../../utils/labels/enumLabel";

export type TrailerRequirementsFormProps = {
  shipment: Pick<
    NewShipmentInput,
    | "trailerType"
    | "loadType"
    | "constraints"
    | "notes"
    | "additionalTrailerTypes"
    | "commodityType"
  >;
  ownedTrailerTypes: TrailerType[];
  onUpdate: (updates: Partial<NewShipmentInputData>) => void;
  errors?: FieldErrors;
};
export const getConstraintValue = (
  name: string,
  constraints: Array<ShipmentConstraintInput | ShipmentConstraint>
) => {
  const constraint = constraints.filter(
    (c) => c.type.toLowerCase() === name.toLowerCase()
  )[0];
  if (!constraint) {
    return null;
  }
  return !isNaN(Number.parseFloat(constraint.value))
    ? Number.parseFloat(constraint.value)
    : null;
};

const liquidTrailerTypes = [TrailerType.Tanker, TrailerType.TankWagon];

export default function TrailerRequirementsForm({
  shipment,
  ownedTrailerTypes,
  onUpdate,
  errors,
}: TrailerRequirementsFormProps) {
  const { t } = useTranslation(["trailers", "common"]);
  const [localConstraints, setLocalConstraints] = useState<
    Array<ShipmentConstraintInput>
  >(shipment.constraints || []);
  const [validationResult, setValidationResult] =
    useState<ValidationResult<Shipment> | null>(null);

  const [isTemperatureSensitive, setIsTemperatureSensitive] = useState<boolean>(
    getConstraintValue("max_temperature", localConstraints) !== null ||
      getConstraintValue("min_temperature", localConstraints) !== null
      ? true
      : false
  );

  const notOwnedTrailerTypes = without(
    Object.values(TrailerType),
    ...ownedTrailerTypes
  );

  const handleTemperatureSensitiveChange = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value?.toLowerCase() === "yes") {
      setIsTemperatureSensitive(true);
      const _constraints = [
        ...localConstraints,
        {
          type: ShipmentConstraintType.MaxTemperature,
          value: "",
          unit: LocaleProvider.getTemperatureUnit(),
        },
        {
          type: ShipmentConstraintType.MinTemperature,
          value: "",
          unit: LocaleProvider.getTemperatureUnit(),
        },
      ];
      setLocalConstraints(_constraints);
      onUpdate({
        constraints: _constraints,
      });
    } else {
      setIsTemperatureSensitive(false);
      const _constraints = localConstraints.filter(
        (c) => !["MAX_TEMPERATURE", "MIN_TEMPERATURE"].includes(c.type)
      );
      setLocalConstraints(_constraints);
      onUpdate({
        constraints: _constraints,
      });
    }
  };

  const handleConstraintChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = event;
    const floatValue = !isNaN(Number.parseFloat(value))
      ? Number.parseFloat(value)
      : null;
    const siUnitValue =
      floatValue !== null
        ? ["length", "width", "height"].includes(name)
          ? floatValue
          : ["min_temperature", "max_temperature"].includes(name)
          ? localTemperatureToCelsius(floatValue)
          : floatValue
        : null;
    const newConstraint = {
      type: name.toUpperCase() as ShipmentConstraintType,
      value: siUnitValue !== null ? String(siUnitValue) : "",
    };

    if (localConstraints.length) {
      const constraints =
        localConstraints.filter(
          (c) => c.type.toLowerCase() !== name.toLowerCase()
        ) || [];

      setLocalConstraints([...constraints, newConstraint]);
      onUpdate({
        constraints: [...constraints, newConstraint],
      });
    } else {
      setLocalConstraints([newConstraint]);
      onUpdate({
        constraints: [newConstraint],
      });
    }
  };

  useEffect(() => {
    const validationResult = trailerRequirementsSchema.validate(shipment, {
      abortEarly: false,
      allowUnknown: true,
    });
    setValidationResult(validationResult);
  }, [shipment]);

  return (
    <Stack spacing={2}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { width: "100%" },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControl>
          <TextField
            label={t("trailerTypes", "Trailer Types")}
            name="trailerType"
            size="small"
            select
            aria-label="Trailer Types select"
            id="trailer-type-select"
            value={[
              shipment.trailerType,
              ...(shipment.additionalTrailerTypes || []),
            ].filter(Boolean)}
            onChange={(event) => {
              const selectedTrailerTypes = event.target
                .value as unknown as TrailerType[];
              onUpdate({
                trailerType: selectedTrailerTypes[0] || null,
                additionalTrailerTypes: selectedTrailerTypes.slice(1),
              });
            }}
            SelectProps={{
              multiple: true,
            }}
            helperText={errors?.trailerType}
            error={!!errors?.trailerType}
          >
            <ListSubheader>{t("owned", "Owned")}</ListSubheader>
            {sortBy(ownedTrailerTypes, identity)
              .filter((type) =>
                shipment.commodityType === ShipmentCommodityType.Liquid
                  ? liquidTrailerTypes.includes(type)
                  : true
              )
              .map((type) => (
                <MenuItem key={type} value={type}>
                  {trailerTypeLabel(type)}
                </MenuItem>
              ))}
            <ListSubheader>{t("others", "Others")}</ListSubheader>
            {sortBy(notOwnedTrailerTypes, identity)
              .filter((type) =>
                shipment.commodityType === ShipmentCommodityType.Liquid
                  ? liquidTrailerTypes.includes(type)
                  : true
              )
              .map((type) => (
                <MenuItem key={type} value={type as TrailerType}>
                  {trailerTypeLabel(type)}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Box>
      {shipment.commodityType !== ShipmentCommodityType.Liquid ? (
        <Box
          component="form"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            "& > :not(style)": { width: "30%" },
          }}
          noValidate
          autoComplete="off"
        >
          <DistanceInput
            name="length"
            onChange={handleConstraintChange}
            size="small"
            value={
              getConstraintValue("length", localConstraints)?.toString() || ""
            }
            inputProps={{ type: "number", min: 0 }}
            InputLabelProps={{ shrink: true }}
            label={`Length (${LocaleProvider.getDistanceUnit()})`}
          />
          <DistanceInput
            name="width"
            onChange={handleConstraintChange}
            size="small"
            value={
              getConstraintValue("width", localConstraints)?.toString() || ""
            }
            inputProps={{ type: "number", min: 0 }}
            InputLabelProps={{ shrink: true }}
            label={`Width (${LocaleProvider.getDistanceUnit()})`}
          />
          <DistanceInput
            name="height"
            onChange={handleConstraintChange}
            size="small"
            value={
              getConstraintValue("height", localConstraints)?.toString() || ""
            }
            inputProps={{ type: "number", min: 0 }}
            InputLabelProps={{ shrink: true }}
            label={`Height (${LocaleProvider.getDistanceUnit()})`}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          "& > :not(style)": { width: "100%" },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormControl
          sx={{
            mr: 2,
          }}
        >
          <TextField
            label={t("orderType", "Order Type")}
            name="loadType"
            size="small"
            select
            value={shipment.loadType || ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onUpdate({
                loadType: event.target.value as LoadType,
              });
            }}
          >
            {Object.values(LoadType).map((loadType, index) => (
              <MenuItem key={index} value={loadType}>
                {t(`loadType.${loadType}`, enumLabel(loadType) || "N/A")}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl>
          <InputLabel id="temperature-sensitive">
            {t("temperatureSensitive", "Temperature Sensitive")}
          </InputLabel>
          <Select
            name="isTemperatureSensitive"
            label={t("temperatureSensitive", "Temperature Sensitive")}
            size="small"
            labelId="temperature-sensitive"
            onChange={handleTemperatureSensitiveChange}
            value={isTemperatureSensitive ? "Yes" : "No"}
          >
            <MenuItem value={"Yes"}>{t("common:yes", "Yes")}</MenuItem>
            <MenuItem value={"No"}>{t("common:no", "No")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {isTemperatureSensitive && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            label={t("trailers:minTemperatureWithUnit", {
              unit: LocaleProvider.getTemperatureUnit(),
              defaultValue: `Min Temperature (${LocaleProvider.getTemperatureUnit()})`,
            })}
            name="min_temperature"
            size="small"
            error={!!getFieldError(validationResult, "constraints")}
            helperText={getFieldError(validationResult, "constraints")}
            onChange={handleConstraintChange}
            value={formatTemperature(
              getConstraintValue("min_temperature", localConstraints),
              false
            )}
            inputProps={{ type: "number" }}
            sx={{
              mr: 2,
              width: 200,
            }}
          />
          <TextField
            label={t("trailers:maxTemperatureWithUnit", {
              unit: LocaleProvider.getTemperatureUnit(),
              defaultValue: `Max Temperature (${LocaleProvider.getTemperatureUnit()})`,
            })}
            name="max_temperature"
            size="small"
            error={!!getFieldError(validationResult, "constraints")}
            helperText={getFieldError(validationResult, "constraints")}
            onChange={handleConstraintChange}
            value={formatTemperature(
              getConstraintValue("max_temperature", localConstraints),
              false
            )}
            inputProps={{ type: "number" }}
            sx={{
              width: 200,
            }}
          />
        </Box>
      )}
    </Stack>
  );
}

import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  ChipProps,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  castArray,
  get,
  isArray,
  isFunction,
  isNull,
  isObject,
  isString,
  isUndefined,
  startCase,
  without,
} from "lodash";
import React, {
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Delete,
  Edit,
  MoreVert,
  ExpandMore,
  ExpandLess,
  Search,
} from "@mui/icons-material";
import LoadingOverlay from "../LoadingOverlay";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import EmptyState from "../EmptyState";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColType,
  GridInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridSingleSelectColDef,
  gridFilteredDescendantCountLookupSelector,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { frFR } from "@mui/x-data-grid/locales";

import {
  CustomField,
  CustomFieldDefinition,
  CustomFieldType,
} from "../../../graphql/generated";
import enumLabel from "../../../utils/labels/enumLabel";
import {
  dateColumnType,
  dateTimeColumnType,
} from "./DataGridDateColumnTypes/DataGridDateColumnTypes";
import DataGridCustomToolbar from "./DataGridCustomToolbar/DataGridCustomToolbar";
import { DataGridCustomRow } from "./DataGridCustomRow/DataGridCustomRow";
import i18next from "i18next";
import { customSelectFilterOperators } from "./customSelectFilterOperators";

export type TableFieldType =
  | "string"
  | "select"
  | "number"
  | "datetime"
  | "boolean"
  | "custom";

const tableFieldTypeToDatagridType: Record<TableFieldType, GridColType> = {
  string: "string",
  number: "number",
  datetime: "dateTime",
  boolean: "boolean",
  custom: "string",
  select: "singleSelect",
};

const TABLE_STATE_PREFIX = "truetms-table-state-v3";

const customFieldDefinitionTypeToDatagridType: Record<
  CustomFieldType,
  GridColType
> = {
  [CustomFieldType.Boolean]: "boolean",
  [CustomFieldType.Date]: "date",
  [CustomFieldType.Datetime]: "dateTime",
  [CustomFieldType.Number]: "number",
  [CustomFieldType.String]: "string",
  [CustomFieldType.Time]: "dateTime",
  [CustomFieldType.Select]: "singleSelect",
  [CustomFieldType.Multiselect]: "singleSelect",
};

export type Renderable = string | number | Date | null | undefined | ReactNode;

export type BasicTableField<T> = {
  value: keyof T | ((item: T) => Renderable);
  subtitle?: keyof T | ((item: T) => Renderable);
  extra?: keyof T | ((item: T) => Renderable);
  label: string | (() => string);
  type: TableFieldType;
  colors?: never;
  key?: string;
  sortBy?: string | string[];
  values?:
    | string[]
    | {
        value: string;
        label: string;
      }[];
};

export type EnumTableField<T, E extends string | number | symbol> = {
  value: (item: T) => E;
  valueLabel?: (value: E) => string;
  subtitle?: never;
  extra?: never;
  label: string;
  type: "enum";
  colors: {
    [key in E]: ChipProps["color"];
  };
  key?: string;
  sortBy?: string | string[];
  values?: E[];
};

export type TableField<T, E extends string | number | symbol = never> =
  | BasicTableField<T>
  | EnumTableField<T, E>;

export type SortField<C extends string> = {
  criteria: C;
  label: string;
};

export type Sort<C> = {
  criteria: C;
  desc: boolean;
};

export type TableAction<T> = {
  icon: ReactNode;
  tooltip: string;
  label?: string;
  isApplicable?: (record: T) => boolean;
  onClick: (record: T) => void;
  secondary?: boolean;
};

type BasicLynksTableProps<T, C extends string = never> = {
  data: Array<T>;
  loading?: boolean;
  loadingRecordIds?: string[];
  sortFields?: Array<SortField<C>>;
  actions?: Array<TableAction<T>>;
  detailsUrlPrefix?: string | ((record: T) => string);
  compact?: boolean;
  showEmptyStateImage?: boolean;
  stickyHeader?: boolean;
  rowStyle?: (record: T) => SxProps<Theme>;
  disableSearch?: boolean;
  onSortChange?: (sort: Sort<C> | null) => void;
  count?: number | null;
  page?: number;
  perPage?: number;
  selected?: T[];
  onRecordClick?: (record: T) => void;
  onSelect?: (records: T[]) => void;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  onRowChange?: (id: string, updatedRecord: T) => void;
  customFieldDefinitions?: Pick<
    CustomFieldDefinition,
    "key" | "type" | "label" | "selectOptions"
  >[];
  customFieldsGetter?: (record: T) => CustomField[];
  id?: string;
  getRecordParentId?: (record: T) => string | null | undefined;
  onFilteredRecordsChange?: (filteredRecords: T[]) => void;
  emptyState?: {
    title?: string;
    message?: string;
    image?: string;
    custom?: JSX.Element;
  };
} & Partial<DataGridProProps>;

type FieldBasedLynksTableProps<
  T,
  E extends string | number | symbol = never,
  C extends string = never
> = BasicLynksTableProps<T, C> & {
  fields: Array<TableField<T, E>>;
  renderRow?: never;
  renderHeader?: never;
  draggable?: boolean;
  onDragStart?: (event: React.DragEvent<HTMLDivElement>, record: T) => void;
  onDragEnd?: (event: React.DragEvent<HTMLDivElement>, record: T) => void;
};

type RenderBasedLynksTableProps<T, C extends string = never> =
  BasicLynksTableProps<T, C> & {
    renderRow: (row: T) => ReactNode;
    renderHeader?: () => ReactNode;
    fields?: never;
    draggable?: never;
    onDragStart?: never;
    onDragEnd?: never;
  };

export type LynksTableProps<
  T,
  E extends string | number | symbol = never,
  C extends string = never
> = FieldBasedLynksTableProps<T, E, C> | RenderBasedLynksTableProps<T, C>;

export default function LynksTable<
  T extends {
    _id: string;
    customFields?: CustomField[] | null;
  },
  E extends string | number | symbol = never,
  C extends string = never
>({
  fields,
  renderRow,
  renderHeader,
  data,
  loading,
  loadingRecordIds,
  sortFields,
  actions,
  detailsUrlPrefix,
  compact,
  showEmptyStateImage,
  stickyHeader,
  disableSearch,
  onSortChange,
  count,
  page,
  perPage,
  selected,
  onRecordClick,
  onSelect,
  onEdit,
  onDelete,
  onRowChange,
  id,
  draggable,
  onDragStart,
  onDragEnd,
  rowStyle,
  customFieldDefinitions,
  customFieldsGetter,
  getRecordParentId,
  onFilteredRecordsChange,
  emptyState,
  onSortModelChange,
  onFilterModelChange,
  onPaginationModelChange,
  ...otherProps
}: LynksTableProps<T, E, C>) {
  const orgId = localStorage.getItem("keycloak::orgId");
  const ORG_TABLE_STATE_PREFIX = useMemo(
    () => `${TABLE_STATE_PREFIX}::${orgId}`,
    [orgId]
  );

  const [sortCriteria] = useState<C | null>(null);
  const [sortDesc] = useState(false);
  const previousCount = useRef(count);
  const [columnsWereResized, setColumnsWereResized] = useState(
    localStorage.getItem(`${ORG_TABLE_STATE_PREFIX}::${id}::columnsResized`) ===
      "true"
  );
  useEffect(() => {
    if (count !== null) {
      previousCount.current = count;
    }
  }, [count]);

  useEffect(() => {
    if (onSortChange) {
      onSortChange(
        sortCriteria
          ? {
              criteria: sortCriteria,
              desc: sortDesc,
            }
          : null
      );
    }
  }, [sortCriteria, onSortChange, sortDesc]);

  const navigate = useNavigate();

  //  More menu handlers
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeMenuRow, setActiveMenuRow] = useState<null | T>(null);
  const isMoreActionsMenuOpened = Boolean(menuAnchorEl);

  const handleActionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: T
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setActiveMenuRow(row);
  };
  const handleActionMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveMenuRow(null);
  };

  const renderValue = useCallback(
    (row: T, field: TableField<T, E>) => {
      const cellValue =
        field.value instanceof Function ? field.value(row) : row[field.value];
      if (isValidElement(cellValue)) {
        return cellValue;
      }
      if (field.type === "boolean") {
        return (
          <Checkbox
            color="primary"
            disabled={field.value instanceof Function || !onRowChange}
            checked={!!cellValue}
            onChange={(event, checked) => {
              if (field.value instanceof Function) {
                // We can't guess the key, this is a custom function field value
                return;
              }
              onRowChange?.(row._id, {
                ...row,
                [field.value]: checked,
              });
            }}
          />
        );
      }
      if (cellValue === null) {
        return null;
      }
      if (field.type === "string") {
        return cellValue !== undefined && cellValue !== null
          ? String(cellValue)
          : "";
      }
      if (field.type === "datetime") {
        return formatDateTime(String(cellValue));
      }
      if (field.type === "enum") {
        const enumValue = field.value(row);
        return (
          <Chip
            label={
              field.valueLabel
                ? field.valueLabel(enumValue)
                : startCase(String(cellValue))
            }
            color={field.colors[enumValue]}
            component="span"
          />
        );
      }
      if (field.type === "select") {
        return isArray(cellValue) ? cellValue.join(", ") : String(cellValue);
      }
      return cellValue as ReactNode;
    },
    [onRowChange]
  );

  const renderSubtitle = useCallback((row: T, field: TableField<T, E>) => {
    if (!field.subtitle) {
      return null;
    }
    const subtitle =
      field.subtitle instanceof Function
        ? field.subtitle(row)
        : row[field.subtitle];
    if (subtitle === null) {
      return null;
    }
    return isValidElement(subtitle) ? subtitle : String(subtitle);
  }, []);

  const renderExtra = useCallback((row: T, field: TableField<T, E>) => {
    if (!field.extra) {
      return null;
    }
    const extra =
      field.extra instanceof Function ? field.extra(row) : row[field.extra];
    if (extra === null) {
      return null;
    }
    return isValidElement(extra) ? extra : String(extra);
  }, []);

  const renderSelectionCheckbox = (row: T) => {
    return onSelect ? (
      <Checkbox
        color="primary"
        checked={selected?.includes(row) || false}
        onClick={(event) => event.stopPropagation()}
        onChange={(event, checked) => {
          event.stopPropagation();
          if (checked) {
            onSelect((selected || []).concat(row));
          } else {
            onSelect(without(selected, row));
          }
        }}
      />
    ) : null;
  };

  const renderField = useCallback(
    (row: T, field: TableField<T, E>): ReactNode => {
      const value = renderValue(row, field);
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          draggable={draggable}
          // onDragStart={(e) => {
          //   e.dataTransfer.setData("text/plain", JSON.stringify(row));
          //   onDragStart?.(e, row);
          // }}
          // onDragEnd={(e) => {
          //   onDragEnd?.(e, row);
          // }}
        >
          <Box>
            {field.type === "custom" || isValidElement(value) ? (
              renderValue(row, field)
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: field.subtitle ? "bold" : "normal",
                }}
              >
                {renderValue(row, field)}
              </Typography>
            )}
            <Typography variant="subtitle2">
              {renderSubtitle(row, field)}
            </Typography>
          </Box>
          {field.extra ? (
            <Box sx={{ pl: 1 }}>{renderExtra(row, field)}</Box>
          ) : null}
        </Box>
      );
    },
    [draggable, renderExtra, renderSubtitle, renderValue]
  );

  const hasActions = onEdit || onDelete || actions?.length;
  const renderActions = (row: T) => {
    const rowIndex = data.indexOf(row);
    return (
      <ClickAwayListener onClickAway={handleActionMenuClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: displayInList ? "column" : "row",
            alignItems: "center",
          }}
        >
          {onEdit && (
            <Tooltip
              title={t("list.edit", "Edit")}
              open={isMoreActionsMenuOpened}
            >
              <IconButton
                id={id ? `${id}-row-${rowIndex}-action-edit` : undefined}
                onClick={(e) => {
                  onEdit(row._id);
                  e.stopPropagation();
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {actions
            ? actions
                .filter((action) =>
                  action.isApplicable ? action.isApplicable(row) : true
                )
                .filter((action) => !action.secondary)
                .map((action, index) => (
                  <Tooltip
                    open={isMoreActionsMenuOpened}
                    title={action.tooltip}
                    key={(action.label || action.tooltip) + index}
                  >
                    {action.label ? (
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          action.onClick(row);
                          e.stopPropagation();
                        }}
                        startIcon={action.icon}
                        sx={{
                          ml: 3,
                        }}
                        size="small"
                        id={
                          id
                            ? `${id}-row-${rowIndex}-action-${action.label}`
                            : undefined
                        }
                      >
                        {action.label}
                      </Button>
                    ) : (
                      <IconButton
                        id={
                          id
                            ? `${id}-row-${rowIndex}-action-${action.tooltip}`
                            : undefined
                        }
                        onClick={(e) => {
                          action.onClick(row);
                          e.stopPropagation();
                        }}
                      >
                        {action.icon}
                      </IconButton>
                    )}
                  </Tooltip>
                ))
            : null}
          {onDelete && (
            <Tooltip
              title={t("delete", "Delete")}
              open={isMoreActionsMenuOpened}
            >
              <IconButton
                id={id ? `${id}-row-${rowIndex}-action-delete` : undefined}
                onClick={(e) => {
                  onDelete(row._id);
                  e.stopPropagation();
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {actions &&
          actions
            .filter((action) =>
              action.isApplicable ? action.isApplicable(row) : true
            )
            .some((action) => !!action.secondary) ? (
            <Tooltip title={t("list.moreActions", "More actions")} key={"more"}>
              <div>
                <IconButton
                  aria-controls={
                    isMoreActionsMenuOpened ? "basic-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={isMoreActionsMenuOpened ? "true" : undefined}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleActionMenuClick(event, row);
                  }}
                  id={id ? `${id}-row-${rowIndex}-action-more` : undefined}
                >
                  <MoreVert />
                </IconButton>
              </div>
            </Tooltip>
          ) : null}
        </Box>
      </ClickAwayListener>
    );
  };

  const renderEmptyState = () => {
    return !loading && !data.length ? (
      emptyState && emptyState.custom ? (
        emptyState.custom
      ) : (
        <EmptyState
          title={
            emptyState
              ? emptyState.title
              : sortCriteria
              ? t("list.noResultFound", "No result found")
              : t("list.nothingHereYet", "Nothing here yet")
          }
          message={
            emptyState
              ? emptyState.message
              : sortCriteria
              ? t(
                  "list.emptyStateDescription",
                  "Try adjusting your search or filter to find what you are looking for."
                )
              : ""
          }
          showImage={showEmptyStateImage}
          image={emptyState?.image}
        />
      )
    ) : null;
  };

  const renderRowLoadingOverlay = (row: T) => {
    return <LoadingOverlay loading={!!loadingRecordIds?.includes(row._id)} />;
  };

  const handleRowClick = useCallback(
    (row: T) => {
      if (detailsUrlPrefix) {
        if (isFunction(detailsUrlPrefix)) {
          navigate(detailsUrlPrefix(row));
        } else {
          navigate(`${detailsUrlPrefix}/${row._id}`);
        }
      } else if (onRecordClick) {
        onRecordClick(row);
      }
    },
    [onRecordClick, navigate, detailsUrlPrefix]
  );

  const initialState: GridInitialState | undefined = useMemo(
    () =>
      localStorage.getItem(`${ORG_TABLE_STATE_PREFIX}::${id}`)
        ? JSON.parse(
            localStorage.getItem(`${ORG_TABLE_STATE_PREFIX}::${id}`) || "{}"
          )
        : undefined,
    [id, ORG_TABLE_STATE_PREFIX]
  );
  const rows: GridRowsProp<T> = data;
  const apiRef = useGridApiRef();

  const actionsColumn: GridColDef<T> = {
    field: "actions",
    headerName: "Actions",
    renderCell: (params) => {
      const row = params.row as T;
      return row._id ? renderActions(row) : "";
    },
    flex: initialState && columnsWereResized ? undefined : 1,
    filterable: false,
  };

  const customFieldsColumns: GridColDef<T>[] = useMemo(
    () =>
      (customFieldDefinitions || []).map((customFieldDefinition) => {
        return {
          field: `customFields.${customFieldDefinition.key}`,
          type: customFieldDefinitionTypeToDatagridType[
            customFieldDefinition.type
          ],
          sortable: false,
          valueOptions: customFieldDefinition.selectOptions?.map(
            (option) => option.key
          ),
          flex: initialState && columnsWereResized ? undefined : 1,
          headerName: customFieldDefinition.label,
          valueGetter(value, row) {
            const computedValue = (
              customFieldsGetter?.(row) || row.customFields
            )?.find(
              (customField) => customField.key === customFieldDefinition.key
            )?.value;
            if (
              customFieldDefinition.type === CustomFieldType.Date ||
              customFieldDefinition.type === CustomFieldType.Datetime
            ) {
              return computedValue ? new Date(computedValue) : null;
            }

            return computedValue;
          },
          valueFormatter: (value) => {
            if (value === null || value === undefined) {
              return "";
            }

            if (customFieldDefinition.type === CustomFieldType.Select) {
              return enumLabel(value);
            }

            if (customFieldDefinition.type === CustomFieldType.Multiselect) {
              return castArray(value).map(enumLabel).join(", ");
            }

            return value;
          },
          ...(customFieldDefinition.type === CustomFieldType.Date
            ? dateColumnType
            : customFieldDefinition.type === CustomFieldType.Datetime
            ? dateTimeColumnType
            : {}),
        };
      }),
    [
      columnsWereResized,
      customFieldDefinitions,
      customFieldsGetter,
      initialState,
    ]
  );

  const columns: GridColDef<T>[] = useMemo(
    () =>
      (
        (fields || []).map((field, index) => {
          const fieldName = field.key
            ? field.key
            : field.value instanceof Function
            ? field.sortBy
              ? castArray(field.sortBy).join("-")
              : field.label instanceof Function
              ? field.label()
              : field.label
            : String(field.value);
          const gridCol: GridColDef<T> = {
            field: fieldName,
            cellClassName: castArray(field.sortBy).join("-"),
            type:
              field.type === "enum" || field.type === "select"
                ? "singleSelect"
                : tableFieldTypeToDatagridType[field.type],
            headerName:
              field.label instanceof Function ? field.label() : field.label,
            valueGetter(value, row) {
              const computedValue = field.sortBy
                ? castArray(field.sortBy)
                    .map((sortByItem) => get(row, sortByItem))
                    .join(" ")
                : field.value instanceof Function
                ? field.value(row)
                : value;
              const subtitle = field.subtitle
                ? field.subtitle instanceof Function
                  ? field.subtitle(row)
                  : row[field.subtitle]
                : null;
              if (field.type === "datetime") {
                return computedValue &&
                  (typeof computedValue === "string" ||
                    typeof computedValue === "number")
                  ? new Date(computedValue)
                  : null;
              }
              if (field.type === "boolean") {
                return isUndefined(computedValue) || isNull(computedValue)
                  ? computedValue
                  : !!computedValue;
              }
              if (field.type === "select") {
                return computedValue;
              }
              if (computedValue === null) {
                return null;
              }
              return `${String(computedValue)} ${subtitle || ""}`.trim();
            },
            ...(field.value instanceof Function
              ? {
                  renderCell: (params) => {
                    return params.row._id ? renderField(params.row, field) : "";
                  },
                }
              : {}),
            flex: columnsWereResized && initialState ? undefined : 1,
            // if a non-string column do not have a sortBy field or key, it should not be filterable/sortable
            filterable: !!(
              field.sortBy ||
              isString(field.value) ||
              field.type !== "string"
            ),
            sortable: !!(
              field.sortBy ||
              isString(field.value) ||
              field.type !== "string"
            ),

            ...(field.type === "datetime" ? dateTimeColumnType : {}),
          };

          if (field.type === "select" || field.type === "enum") {
            gridCol.filterOperators = customSelectFilterOperators;
            (gridCol as GridSingleSelectColDef).valueOptions = (
              field.values || []
            ).map((v) =>
              field.type === "enum"
                ? {
                    value: v,
                    label: enumLabel(String(v)),
                  }
                : isObject(v)
                ? v
                : String(v)
            );
          }

          return gridCol;
        }) as GridColDef<T>[]
      ).concat(customFieldsColumns),
    [columnsWereResized, customFieldsColumns, fields, initialState, renderField]
  );

  const columnsWithActions = useMemo(
    () =>
      [
        GRID_CHECKBOX_SELECTION_COL_DEF,
        ...columns,
        ...(hasActions ? [actionsColumn] : []),
      ].map((column) => ({
        ...column,
        width: columnsWereResized
          ? // @ts-ignore
            initialState?.columns?.lookup?.[column.field]?.width
          : undefined,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEdit, onDelete]
  );

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "Expand",
    renderCell: (params: GridRenderCellParams) => (
      <CustomGridTreeDataGroupingCell {...params} />
    ),
  };

  const theme = useTheme();
  const displayInList = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("common");

  const getRowId = useCallback((row: T) => row._id, []);
  const onRowClick = useCallback(
    (params: GridRowParams<T>) => {
      onSelect?.([params.row as T]);
      handleRowClick(params.row as T);
    },
    [handleRowClick, onSelect]
  );
  const onStateChange = useCallback(
    (state: any) => {
      if (id) {
        setTimeout(() => {
          localStorage.setItem(
            `${ORG_TABLE_STATE_PREFIX}::${id}`,
            JSON.stringify(state)
          );
        });
      }

      if (onFilteredRecordsChange) {
        const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        onFilteredRecordsChange(
          data.filter((row) => filteredRowIds.includes(row._id))
        );
      }
    },
    [id, apiRef, onFilteredRecordsChange, data, ORG_TABLE_STATE_PREFIX]
  );

  useEffect(() => {
    if (initialState) {
      return;
    }
    setTimeout(() => {
      const columns = apiRef.current?.getAllColumns?.() || [];
      const columnsComputedWidths = columns.map(
        (column) => column.computedWidth
      );
      apiRef.current?.updateColumns?.(
        columns.map((col) => ({
          ...col,
          flex: undefined,
        }))
      );
      columns.forEach((column, index) => {
        apiRef.current?.setColumnWidth?.(
          column.field,
          columnsComputedWidths[index]
        );
      });
    });
  }, [apiRef, initialState]);

  useEffect(() => {
    if (columnsWereResized) {
      localStorage.setItem(
        `${ORG_TABLE_STATE_PREFIX}::${id}::columnsResized`,
        "true"
      );
    }
  }, [columnsWereResized, id, ORG_TABLE_STATE_PREFIX]);

  const initialSortingFilteringApplied = useRef(false);

  useEffect(() => {
    if (initialSortingFilteringApplied.current) {
      return;
    }
    if (!initialState) {
      return;
    }
    if (
      initialState.pagination?.paginationModel?.page !== undefined &&
      initialState.pagination?.paginationModel?.pageSize !== undefined
    ) {
      onPaginationModelChange?.(
        {
          page: initialState.pagination.paginationModel.page,
          pageSize: initialState.pagination.paginationModel.pageSize,
        },
        {
          api: apiRef.current,
        }
      );
    }

    if (initialState.sorting?.sortModel) {
      const sortModel = initialState.sorting.sortModel;
      onSortModelChange?.(sortModel, {
        api: apiRef.current,
      });
    }

    if (initialState.filter?.filterModel) {
      const filterModel = initialState.filter.filterModel;
      onFilterModelChange?.(filterModel, {
        api: apiRef.current,
      });
    }
    initialSortingFilteringApplied.current = true;
  }, [
    apiRef,
    initialState,
    onPaginationModelChange,
    onSortModelChange,
    onFilterModelChange,
  ]);

  const initialSelectionApplied = useRef(false);

  useEffect(() => {
    if (initialSelectionApplied.current) {
      return;
    }
    if (!apiRef.current.selectRows) {
      return;
    }
    apiRef.current.selectRows((selected || []).map((row) => row._id));
    initialSelectionApplied.current = true;
  }, [selected, apiRef]);

  // Clear selection when data changes
  // TODO: to be troubleshooted, table get refresh and remove selections
  // useEffect(() => {
  //   apiRef.current?.setRowSelectionModel?.([]);
  // }, [data, apiRef]);

  return (
    <Grid
      id={id}
      sx={{
        height: "100%",
      }}
    >
      {displayInList && fields ? (
        <Grid sx={{ position: "relative" }}>
          <LoadingOverlay loading={loading || false} />
          <List aria-label="shipments list">
            <List>
              {data.map((row, i) => (
                <Box>
                  <ListItemButton
                    onClick={() => handleRowClick(row)}
                    key={i}
                    selected={selected?.includes(row) || false}
                  >
                    {renderSelectionCheckbox(row)}
                    {renderRowLoadingOverlay(row)}
                    <List
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      {fields.map((field, index) => (
                        <ListItem key={String(field.value || index)}>
                          {renderField(row, field)}
                        </ListItem>
                      ))}
                    </List>
                    <List>{renderActions(row)}</List>
                  </ListItemButton>
                  <Divider />
                </Box>
              ))}
            </List>
          </List>
          {renderEmptyState()}
        </Grid>
      ) : fields ? (
        <Box
          sx={{
            height: "100%",
          }}
        >
          <DataGridPro
            localeText={
              i18next.language === "fr"
                ? {
                    ...frFR.components.MuiDataGrid.defaultProps.localeText,
                    columnsManagementReset: t("list.resetColumns", "Reset"),
                  }
                : undefined
            }
            rows={rows}
            columns={columnsWithActions}
            getRowId={getRowId}
            apiRef={apiRef}
            checkboxSelection={!!onSelect}
            isRowSelectable={(row: GridRowParams<T>) => !!row.row._id}
            // autoHeight
            onRowSelectionModelChange={(selected) => {
              const selectedIDs = new Set(selected);
              const selectedRows = rows.filter((row) =>
                selectedIDs.has(row._id)
              );
              onSelect?.(selectedRows);
            }}
            onRowClick={onRowClick}
            sx={(theme) => ({
              mt: 1,
              ".MuiDataGrid-columnHeader": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                "& path": {
                  fill: theme.palette.primary.contrastText,
                },
              },
              ".MuiDataGrid-row": {},
              ".MuiDataGrid-filler": {},
              ".MuiDataGrid-cell": {
                display: "flex",
              },
              minHeight: 200,
            })}
            pagination
            // disableVirtualization
            initialState={id ? initialState : undefined}
            onStateChange={onStateChange}
            onColumnResize={() => {
              setColumnsWereResized(true);
            }}
            loading={loading}
            slots={{
              toolbar: DataGridCustomToolbar,
              row: DataGridCustomRow,
              noRowsOverlay: renderEmptyState,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: !disableSearch,
                quickFilterProps: {
                  quickFilterParser: (searchInput) => [searchInput],
                },
              },
              // @ts-expect-error
              row: {
                onDragStart: (e: React.DragEvent<HTMLDivElement>, row: T) => {
                  e.dataTransfer.setData("text/plain", JSON.stringify(row));
                  onDragStart?.(e, row);
                },
                onDragEnd: (e: React.DragEvent<HTMLDivElement>, row: T) => {
                  onDragEnd?.(e, row);
                },
              },
            }}
            treeData={!!getRecordParentId}
            getTreeDataPath={(row) => {
              const parentId = getRecordParentId?.(row);
              return parentId ? [parentId, row._id] : [row._id];
            }}
            groupingColDef={groupingColDef}
            onPaginationModelChange={onPaginationModelChange}
            onSortModelChange={onSortModelChange}
            onFilterModelChange={onFilterModelChange}
            {...otherProps}
          />
        </Box>
      ) : renderRow ? (
        <Stack spacing={2}>
          <TextField
            label={t("search", "Search")}
            InputProps={{
              startAdornment: <Search />,
            }}
            onChange={(event) => {
              onFilterModelChange?.(
                {
                  items: [],
                  quickFilterValues: [event.target.value],
                },
                {
                  api: apiRef.current,
                }
              );
            }}
            sx={{
              alignSelf: "flex-start",
            }}
          />
          {renderHeader?.()}
          {data.map((record) => renderRow(record))}
          <Pagination
            sx={{
              alignSelf: "flex-end",
              mb: 10,
            }}
            count={Math.ceil((count || 0) / (perPage || 1))}
            page={page || 1}
            onChange={(event, page) => {
              onPaginationModelChange?.(
                {
                  page: page - 1,
                  pageSize: perPage || 0,
                },
                {
                  api: apiRef.current,
                }
              );
            }}
          />
        </Stack>
      ) : null}

      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={isMoreActionsMenuOpened}
        onClose={handleActionMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {activeMenuRow && actions
          ? actions
              .filter((action) =>
                action.isApplicable ? action.isApplicable(activeMenuRow) : true
              )
              .filter((action) => !!action.secondary)
              .map((action, index) => (
                <Tooltip
                  title={action.tooltip}
                  key={(action.label || action.tooltip) + index}
                >
                  <MenuItem onClick={() => action.onClick(activeMenuRow)}>
                    {action.icon ? (
                      <IconButton>{action.icon}</IconButton>
                    ) : null}
                    {action.label}
                  </MenuItem>
                </Tooltip>
              ))
          : null}
      </Menu>
    </Grid>
  );
}

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps["onClick"] = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <div>
      {filteredDescendantCount > 0 ? (
        <>
          <Button onClick={handleClick} tabIndex={-1} size="small">
            {rowNode.type === "group" && rowNode.childrenExpanded ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
            <Typography>View Children</Typography>
          </Button>
        </>
      ) : (
        <span />
      )}
    </div>
  );
}

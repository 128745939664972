import { ChartContainer } from "@mui/x-charts";
import {
  ForecastingModelSlotType,
  GetReceiverForecastDetailsQuery,
} from "../../../graphql/generated";

import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot } from "@mui/x-charts/LineChart";
import {
  AllSeriesType,
  AxisConfig,
  ChartsXAxisProps,
} from "@mui/x-charts/models";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { groupBy, map, uniqBy } from "lodash";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import alpha from "color-alpha";
import { useMemo } from "react";
import "./ForecastModelVisualizer.css";
import { format, setDay, setMonth } from "date-fns";

type ForecastModelVisualizerProps = {
  receiverForecast: GetReceiverForecastDetailsQuery["receiverForecastById"];
};

const now = new Date();

const colors = ["blue", "orange", "black", "yellow"];

const ForecastModelVisualizer = (props: ForecastModelVisualizerProps) => {
  const { receiverForecast } = props;

  const charts = useMemo(() => {
    const modelsByPeriodicity = groupBy(
      receiverForecast.models || [],
      (model) => model.slots?.[0]?.type
    );

    return map(modelsByPeriodicity, (models, periodicity) => {
      const xAxis: AxisConfig<"band", number, ChartsXAxisProps> =
        periodicity === ForecastingModelSlotType.Daily ||
        periodicity === ForecastingModelSlotType.Hourly
          ? {
              id: "days",
              data: [1, 2, 3, 4, 5, 6, 0],
              scaleType: "band",
              valueFormatter: (value) => format(setDay(now, value), "EEE"),
            }
          : periodicity === ForecastingModelSlotType.Weekly
          ? {
              id: "week",
              data: [1],
              scaleType: "band",
              valueFormatter: (value) => `Weekly`,
            }
          : {
              id: "month",
              data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
              scaleType: "band",
              valueFormatter: (value) => format(setMonth(now, value), "MMM"),
            };

      const series: AllSeriesType[] = models.flatMap((model, index) => {
        const commodities = receiverForecast.receiver?.storageFacilities?.find(
          (sf) => sf.identifier === model.storageFacilityId
        )?.commodities;

        const commoditiesLabel =
          commodities?.map((commodity) => commodity?.label).join(", ") || "";

        if (periodicity === ForecastingModelSlotType.Daily) {
          return {
            type: "bar",
            yAxisKey: "sales",
            label: `${commoditiesLabel}`,
            color: colors[index % colors.length],
            data: xAxis.data?.map((dayOfWeek) => {
              const slot = model.slots?.find(
                (slot) => slot.daily?.dayOfWeek === dayOfWeek
              );
              return slot?.salesAmount || 0;
            }),
          };
        } else if (periodicity === ForecastingModelSlotType.Hourly) {
          const formatRange = (
            range: { hour: number; minute: number } | undefined
          ) =>
            range
              ? `${String(range.hour).padStart(2, "0")}:${String(
                  range.minute
                ).padStart(2, "0")}`
              : "N/A";
          const hourRanges = uniqBy(
            model.slots?.map((slot) => [
              slot.hourly?.startTime,
              slot.hourly?.endTime,
            ]),
            (range) =>
              `${range[0]?.hour}::${range[0]?.minute}--${range[1]?.hour}::${range[1]?.minute}`
          );
          return hourRanges.map((hourRange, hourRangeIndex) => {
            return {
              type: "bar",
              yAxisKey: "sales",
              stack: commoditiesLabel,
              label: `${commoditiesLabel} - ${formatRange(
                hourRange[0]
              )} - ${formatRange(hourRange[1])}`,
              color: alpha(
                colors[index % colors.length],
                (hourRangeIndex + 1) / (hourRanges.length + 2)
              ),
              data: xAxis.data?.map((dayOfWeek) => {
                const slot = model.slots?.find(
                  (slot) =>
                    slot.hourly?.dayOfWeek === dayOfWeek &&
                    slot.hourly?.startTime?.hour === hourRange[0]?.hour &&
                    slot.hourly?.startTime?.minute === hourRange[0]?.minute &&
                    slot.hourly?.endTime?.hour === hourRange[1]?.hour &&
                    slot.hourly?.endTime?.minute === hourRange[1]?.minute
                );
                return slot?.salesAmount || 0;
              }),
            };
          });
        } else if (periodicity === ForecastingModelSlotType.Weekly) {
          return {
            type: "bar",
            yAxisKey: "sales",
            label: `${commoditiesLabel || ""}`,
            color: colors[index % colors.length],
            data: xAxis.data?.map((week) => {
              const slot = model.slots?.[0];
              return slot?.salesAmount || 0;
            }),
          };
        } else if (periodicity === ForecastingModelSlotType.Monthly) {
          return {
            type: "bar",
            yAxisKey: "sales",
            label: `${commoditiesLabel || ""}`,
            color: colors[index % colors.length],
            data: xAxis.data?.map((month) => {
              const slot = model.slots?.find(
                (slot) => slot.monthly?.month === month
              );
              return slot?.salesAmount || 0;
            }),
          };
        }

        return [];
      });

      return {
        xAxis,
        series,
      };
    });
  }, [receiverForecast.models, receiverForecast.receiver?.storageFacilities]);

  return (
    <>
      {charts.map((chart) => (
        <ChartContainer
          series={chart.series}
          width={800}
          height={600}
          sx={{
            marginTop: "100px",
            overflow: "visible",
          }}
          xAxis={[chart.xAxis]}
          yAxis={[
            {
              id: "sales",
              scaleType: "linear",
            },
          ]}
        >
          <ChartsLegend
            classes={{
              root: "model-visualizer-legend-root",
            }}
          />
          <ChartsTooltip />
          <BarPlot />
          <LinePlot />
          <ChartsXAxis label="Date" position="bottom" axisId={chart.xAxis.id} />
          <ChartsYAxis label="Sales" position="left" axisId="sales" />
        </ChartContainer>
      ))}
    </>
  );
};

export default ForecastModelVisualizer;

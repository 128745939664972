import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import BestBuyRankingListContainer, {
  BestBuyRankingListContainerProps,
} from "../BestBuyRankingList";
import { BestBuyRankingListItem } from "../BestBuyRankingList/BestBuyRankingList";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

export type BestBuyModalProps = Omit<
  BestBuyRankingListContainerProps,
  "onBestBuyItemSelect"
> & {
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  onBestBuyItemSubmit: (item: BestBuyRankingListItem) => void;
};

const BestBuyModal = ({
  open,
  loading,
  onClose,
  onBestBuyItemSubmit,
  ...props
}: BestBuyModalProps) => {
  const { t } = useTranslation(["common", "orders"]);

  const [bestBuyItemSelected, setBestBuyItemSelected] =
    useState<BestBuyRankingListItem | undefined>(undefined);

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="xl"
    >
      <DialogTitle>
        {t("orders:bestbuy.ranking", "TrueSource Ranking")}
      </DialogTitle>
      <DialogContent
        sx={{
          width: 1000,
          height: 500,
        }}
      >
        <BestBuyRankingListContainer
          {...props}
          onBestBuyItemSelect={(item) => setBestBuyItemSelected(item)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            if (bestBuyItemSelected) {
              onBestBuyItemSubmit(bestBuyItemSelected);
            } else {
              onClose();
            }
          }}
        >
          {t("common:apply", "Apply")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BestBuyModal;

import { Autocomplete, Stack, TextField } from "@mui/material";
import {
  BusinessEntityType,
  GetGoodProfileListQuery,
  StorageFacility,
  StorageFacilityUnit,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import NumberTextField from "../../common/NumberTextField";
import EnumSelect from "../../common/EnumSelect";
import BusinessEntitySelectContainer from "../../shipment/ShipmentForm/BusinessEntitySelect";
import toDefaultPrecision, {
  parseFloatWithDefaultPrecision,
} from "../../../utils/conversion/numberPrecision";

type StorageFacilityFormProps = {
  storageFacility: Partial<StorageFacility>;
  onChange: (storageFacility: Partial<StorageFacility>) => void;
  goodProfiles: GetGoodProfileListQuery["goodProfiles"]["data"];
};

const StorageFacilityForm = ({
  storageFacility,
  onChange,
  goodProfiles,
}: StorageFacilityFormProps) => {
  const selectedGoodProfiles = goodProfiles.filter(
    (good) => storageFacility.commodityIds?.includes(good._id) || false
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ mb: 2, mt: 1 }}
      alignItems={"flex-start"}
    >
      <TextField
        label="Identifier"
        sx={{
          flex: 0.5,
        }}
        required
        name="storageFacility.identifier"
        value={storageFacility.identifier || ""}
        onChange={(event) => {
          onChange({
            ...storageFacility,
            identifier: event.target.value,
          });
        }}
      />
      <Stack spacing={1} flex={1}>
        <NumberTextField
          label="Capacity"
          fullWidth
          required
          name="storageFacility.capacity"
          value={String(storageFacility.capacity || "")}
          onChange={(event) => {
            onChange({
              ...storageFacility,
              capacity: parseFloat(event.target.value),
            });
          }}
        />
        <Stack direction="column" spacing={1}>
          <Stack direction="row">
            <NumberTextField
              label="Shut Down Amount"
              fullWidth
              name="storageFacility.shutDownLevel"
              value={String(storageFacility.shutDownLevel || "")}
              onChange={(event) => {
                onChange({
                  ...storageFacility,
                  shutDownLevel: parseFloatWithDefaultPrecision(
                    event.target.value
                  ),
                });
              }}
            />
            <NumberTextField
              label="%"
              name="storageFacility.shutDownLevelPercentage"
              value={toDefaultPrecision(
                ((storageFacility.shutDownLevel || 0) /
                  (storageFacility.capacity || 1)) *
                  100
              ).toString()}
              onChange={(event) => {
                const shutDownLevelPercentage = parseFloat(event.target.value);
                onChange({
                  ...storageFacility,
                  shutDownLevel: toDefaultPrecision(
                    ((shutDownLevelPercentage || 0) / 100) *
                      (storageFacility.capacity || 1)
                  ),
                });
              }}
            />
          </Stack>
          <Stack direction="row">
            <NumberTextField
              label="Safe Fill Amount"
              fullWidth
              name="storageFacility.safeFillLevel"
              value={String(storageFacility.safeFillLevel || "")}
              onChange={(event) => {
                onChange({
                  ...storageFacility,
                  safeFillLevel: parseFloatWithDefaultPrecision(
                    event.target.value
                  ),
                });
              }}
            />
            <NumberTextField
              label="%"
              name="storageFacility.safeFillLevelPercentage"
              value={toDefaultPrecision(
                ((storageFacility.safeFillLevel || 0) /
                  (storageFacility.capacity || 1)) *
                  100
              ).toString()}
              onChange={(event) => {
                const safeFillLevelPercentage = parseFloat(event.target.value);
                onChange({
                  ...storageFacility,
                  safeFillLevel: toDefaultPrecision(
                    ((safeFillLevelPercentage || 0) / 100) *
                      (storageFacility.capacity || 1)
                  ),
                });
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <EnumSelect
        enumObject={StorageFacilityUnit}
        label="UOM"
        fullWidth
        required
        name="storageFacility.unit"
        value={storageFacility.unit}
        onChange={(event, value) => {
          if (!value) {
            return;
          }
          onChange({
            ...storageFacility,
            unit: value,
          });
        }}
        sx={{
          flex: 0.5,
        }}
      />

      {goodProfiles ? (
        <Autocomplete
          options={goodProfiles}
          sx={{
            flex: 1,
          }}
          value={selectedGoodProfiles || null}
          getOptionLabel={(goodProfile) => {
            return goodProfile.label;
          }}
          getOptionDisabled={(goodProfile) => {
            if (!selectedGoodProfiles.length) {
              return false;
            }
            const selectedGoodProfilesClassIds = selectedGoodProfiles.map(
              (goodProfile) => goodProfile.goodProfileClassId
            );
            if (
              !selectedGoodProfilesClassIds.includes(
                goodProfile.goodProfileClassId
              )
            ) {
              return true;
            }
            return false;
          }}
          multiple
          size="small"
          renderInput={(inputParams) => (
            <TextField
              {...inputParams}
              required
              fullWidth
              name="label"
              label="Commodities"
              size="medium"
            />
          )}
          onChange={(event, value) => {
            onChange({
              ...storageFacility,
              commodityIds: value.map((goodProfile) => goodProfile._id),
            });
          }}
        />
      ) : null}

      <BusinessEntitySelectContainer
        inputProps={{
          label: "Supplier",
          size: "medium",
        }}
        sx={{
          flex: 1,
        }}
        value={storageFacility.defaultSupplierId || null}
        businessEntityType={BusinessEntityType.Supplier}
        onChange={(businessEntityId) => {
          onChange({
            ...storageFacility,
            defaultSupplierId: businessEntityId,
          });
        }}
        filterBusinessEntityIds={(
          (selectedGoodProfiles || []).flatMap(
            (goodProfile) => goodProfile.supplierIds || []
          ) || []
        ).concat(storageFacility.defaultSupplierId || [])}
      />
    </Stack>
  );
};

type StorageFacilityFormContainerProps = Omit<
  StorageFacilityFormProps,
  "goodProfiles"
>;

const StorageFacilityFormContainer = (
  props: StorageFacilityFormContainerProps
) => {
  const goodProfilesQuery = useGetGoodProfileListQuery();
  const goodProfiles = goodProfilesQuery.data?.goodProfiles.data || [];
  return <StorageFacilityForm {...props} goodProfiles={goodProfiles} />;
};

export default StorageFacilityFormContainer;

export { StorageFacilityForm as StorageFacilityFormComponent };

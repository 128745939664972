import { useGetCustomerShipmentListQuery } from "../../../graphql/generated";
import CustomerOrdersList from "./CustomerOrdersList";

export default function CustomerOrdersListContainer() {
  let refetch = () => {};

  const useGetCustomerShipmentListQueryCustom: typeof useGetCustomerShipmentListQuery =
    (variables, options) => {
      const query = useGetCustomerShipmentListQuery(variables, {
        ...options,
      });
      refetch = query.refetch;
      return query;
    };
  useGetCustomerShipmentListQueryCustom.fetcher =
    useGetCustomerShipmentListQuery.fetcher;
  useGetCustomerShipmentListQueryCustom.getKey =
    useGetCustomerShipmentListQuery.getKey;

  return (
    <CustomerOrdersList
      query={useGetCustomerShipmentListQueryCustom}
      onRefetch={() => {
        refetch();
      }}
    />
  );
}

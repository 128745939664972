import { ChartContainer } from "@mui/x-charts";
import {
  ForecastingModelSlotType,
  GetReceiverForecastDetailsQuery,
  GetReceiverReadingsQuery,
} from "../../../graphql/generated";

import { formatDate } from "../../../utils/labels/formatDateTime";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot } from "@mui/x-charts/LineChart";
import { AllSeriesType } from "@mui/x-charts/models";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { addDays, format } from "date-fns";
import { last, sortBy, sum } from "lodash";
import { ChartsLegend } from "@mui/x-charts/ChartsLegend";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import alpha from "color-alpha";
import { useMemo } from "react";
import commodityLabel from "../../../utils/labels/commodityLabel";

type ReceiverForecastVisualizerProps = {
  readings: GetReceiverReadingsQuery["storageFacilityReadings"]["data"];
  receiverForecast: GetReceiverForecastDetailsQuery["receiverForecastById"];
  showTankLevels?: boolean;
};

const now = new Date();

export const colors = ["blue", "orange", "black", "yellow"];

const ReceiverForecastVisualizer = (props: ReceiverForecastVisualizerProps) => {
  const { receiverForecast, readings } = props;

  const series = useMemo(() => {
    const xAxisDates = [-6, -5, -4, -3, -2, -1, 0].map((nbOfDaysBack) =>
      addDays(now, nbOfDaysBack)
    );
    const averageSalesSeries: AllSeriesType[] = (
      receiverForecast.models || []
    ).flatMap((model, index) => {
      const commodities = receiverForecast.receiver?.storageFacilities?.find(
        (sf) => sf.identifier === model.storageFacilityId
      )?.commodities;

      const commoditiesLabel =
        commodities?.map((commodity) => commodity.label).join(", ") || "";

      if (model.slots?.[0]?.type === ForecastingModelSlotType.Daily) {
        return {
          type: "line",
          yAxisKey: "sales",
          curve: "linear",
          label: `${commoditiesLabel || ""} - Average`,
          color: alpha(colors[index % colors.length], 1),
          data: xAxisDates.map((date) => {
            const slot = model.slots?.find(
              (slot) => slot.daily?.dayOfWeek === date.getDay()
            );
            return slot?.salesAmount || 0;
          }),
        };
      } else if (model.slots?.[0].type === ForecastingModelSlotType.Hourly) {
        return {
          type: "line",
          yAxisKey: "sales",
          curve: "linear",
          label: `${commoditiesLabel || ""} - Average`,
          color: alpha(colors[index % colors.length], 1),
          data: xAxisDates.map((date) => {
            const slotsOfDay = model.slots?.filter(
              (slot) => slot.hourly?.dayOfWeek === date.getDay()
            );
            const salesAmounts = slotsOfDay?.map((slot) => slot.salesAmount);
            return sum(salesAmounts || []);
          }),
        };
      }

      return [];
    });

    const salesSeries: AllSeriesType[] = (receiverForecast.models || []).map(
      (model, index) => {
        const commodities = receiverForecast.receiver?.storageFacilities?.find(
          (sf) => sf.identifier === model.storageFacilityId
        )?.commodities;

        const commoditiesLabel =
          commodities?.map(commodityLabel).join(", ") || "";

        return {
          type: "bar",
          yAxisKey: "sales",
          label: `${commoditiesLabel || ""} - Sales`,
          color: alpha(colors[index % colors.length], 0.4),
          data: [-6, -5, -4, -3, -2, -1, 0]
            .map((nbOfDaysBack) => addDays(now, nbOfDaysBack))
            .map((date) => {
              const dateReadings = readings.filter(
                (reading) =>
                  reading.storageFacilityId === model.storageFacilityId &&
                  formatDate(reading.date) === formatDate(date)
              );

              const allSalesAmounts = dateReadings.map((reading, index) => {
                const previousReading = dateReadings[index - 1];
                if (!previousReading) {
                  return 0;
                }
                if (reading.level >= previousReading.level) {
                  return 0;
                }
                return previousReading.level - reading.level;
              });

              const salesAmount = sum(allSalesAmounts);
              return salesAmount;
            }),
        };
      }
    );

    const tankLevelSeries: AllSeriesType[] = props.showTankLevels
      ? receiverForecast.models.map((model, index) => {
          const commodities =
            receiverForecast.receiver?.storageFacilities?.find(
              (sf) => sf.identifier === model.storageFacilityId
            )?.commodities;

          const commoditiesLabel =
            commodities?.map((commodity) => commodity.label).join(", ") || "";

          return {
            type: "line",
            yAxisKey: "level",
            curve: "monotoneX",
            area: true,
            label: `${commoditiesLabel || ""} - Level`,
            color: alpha(colors[index % colors.length], 0.2),
            data: [-6, -5, -4, -3, -2, -1, 0]
              .map((nbOfDaysBack) => addDays(now, nbOfDaysBack))
              .map((date) => {
                const lastReadingOfDate = last(
                  sortBy(
                    readings.filter(
                      (reading) =>
                        reading.storageFacilityId === model.storageFacilityId &&
                        formatDate(reading.date) === formatDate(date)
                    ),
                    (reading) => reading.date
                  )
                );

                return lastReadingOfDate?.level || 0;
              }),
          };
        })
      : [];

    const series: AllSeriesType[] = [
      ...averageSalesSeries,
      // {
      //   type: "bar",
      //   stack: "",
      //   yAxisKey: "sales",
      //   data: [2, 5, 3, 4, 1],
      // },
      // {
      //   type: "bar",
      //   stack: "",
      //   yAxisKey: "sales",
      //   data: [5, 6, 2, 8, 9],
      // },
      ...salesSeries,
      ...tankLevelSeries,
    ];
    return series;
  }, [
    readings,
    receiverForecast.models,
    receiverForecast.receiver?.storageFacilities,
    props.showTankLevels,
  ]);

  return (
    <ChartContainer
      series={series}
      width={800}
      height={400}
      xAxis={[
        {
          id: "dates",
          data: [-6, -5, -4, -3, -2, -1, 0].map((nbOfDaysBack) =>
            addDays(now, nbOfDaysBack)
          ),
          scaleType: "band",
          valueFormatter: (value) => format(value, "EEE dd MMM"),
        },
      ]}
      yAxis={[
        {
          id: "sales",
          scaleType: "linear",
        },
        {
          id: "level",
          scaleType: "linear",
        },
      ]}
    >
      <BarPlot />
      <LinePlot />
      <ChartsXAxis label="Date" position="bottom" axisId="dates" />
      <ChartsYAxis label="Sales" position="left" axisId="sales" />
      <ChartsYAxis label="Level" position="right" axisId="level" />
      <ChartsLegend />
      <ChartsTooltip />
    </ChartContainer>
  );
};

export default ReceiverForecastVisualizer;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { hideDialog } from "../../../redux/slices/alert/Alert.slice";

const GlobalDialog = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state: RootState) => state.alertReducer.dialog);
  const {
    isVisible: isDialogVisible,
    title,
    description,
    type: dialogType,
    actions,
  } = dialog;
  return (
    <Dialog
      open={!!isDialogVisible}
      onClose={() => dispatch(hideDialog())}
      aria-labelledby="dialog-confirmation"
      aria-describedby="dialog-confirmation"
      sx={{
        zIndex: 9999,
      }}
    >
      <DialogTitle
        sx={{
          color:
            dialogType === "primary"
              ? "primary.main"
              : dialogType === "secondary"
              ? "secondary.main"
              : "error.main",
        }}
        id="dialog-confirmation"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {(
          actions || [
            {
              title: "OK",
              type: dialogType,
            },
          ]
        ).map((action) => (
          <Button
            color={action.type}
            onClick={action.onClick || (() => dispatch(hideDialog()))}
            key={action.title}
            id={`dialog-action-${action.title}`}
          >
            {action.title}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default GlobalDialog;

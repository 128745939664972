import { useSnackbar } from "notistack";
import {
  InvoiceStatus,
  useGenerateInvoiceMutation,
  useGetInvoiceDetailsQuery,
  useGetOrganizationSettingsQuery,
  useGetShipmentInvoiceDetailsQuery,
  useSendInvoiceMutation,
  useUpdateInvoiceStatusMutation,
  useUpdateInvoiceTaxRateMutation,
} from "../../../graphql/generated";
import useDialog from "../../../utils/hooks/useDialog";
import DownloadSendInvoiceButtons from "./DownloadSendInvoiceButtons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { queryClient } from "../../../App";

type ShipmentBasedDownloadSendInvoiceButtonsContainerProps = {
  shipmentId: string;
  canGenerateInvoice?: boolean;
  invoiceHasChanged?: boolean;
};

type InvoiceBasedDownloadSendInvoiceButtonsContainerProps = {
  invoiceId: string;
  canGenerateInvoice?: boolean;
  invoiceHasChanged?: boolean;
};

type DownloadSendInvoiceButtonsContainerProps =
  | ShipmentBasedDownloadSendInvoiceButtonsContainerProps
  | InvoiceBasedDownloadSendInvoiceButtonsContainerProps;

const DownloadSendInvoiceButtonsContainer = (
  props: DownloadSendInvoiceButtonsContainerProps
) => {
  const shipmentId = "shipmentId" in props ? props.shipmentId : null;
  const invoiceId = "invoiceId" in props ? props.invoiceId : null;
  const { canGenerateInvoice = true, invoiceHasChanged } = props;
  const { t } = useTranslation(["finance", "common"]);
  const getShipmentInvoiceDetailsQueryByShipmentId =
    useGetShipmentInvoiceDetailsQuery(
      {
        shipmentId: shipmentId || "",
      },
      {
        refetchInterval(data) {
          if (data?.invoiceByShipmentId?.pdfDocument.isGenerating) {
            return 1000;
          }
          return false;
        },
        enabled: !!shipmentId,
      }
    );

  const getShipmentInvoiceDetailsQueryByInvoiceId = useGetInvoiceDetailsQuery(
    {
      id: invoiceId || "",
    },
    {
      refetchInterval(data) {
        if (data?.invoiceById?.pdfDocument.isGenerating) {
          return 1000;
        }
        return false;
      },
      enabled: !!invoiceId,
    }
  );

  const getShipmentInvoiceDetailsQuery = shipmentId
    ? getShipmentInvoiceDetailsQueryByShipmentId
    : getShipmentInvoiceDetailsQueryByInvoiceId;

  const invoice = shipmentId
    ? getShipmentInvoiceDetailsQueryByShipmentId.data?.invoiceByShipmentId
    : getShipmentInvoiceDetailsQueryByInvoiceId.data?.invoiceById;

  useEffect(() => {
    getShipmentInvoiceDetailsQuery.refetch();
  }, [invoiceHasChanged, getShipmentInvoiceDetailsQuery]);
  const generateInvoiceMutation = useGenerateInvoiceMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { showDialog } = useDialog();

  const sendInvoiceMutation = useSendInvoiceMutation();
  const updateInvoiceStatusMutation = useUpdateInvoiceStatusMutation();
  const updateInvoiceTaxRateMutation = useUpdateInvoiceTaxRateMutation();
  const orgSettingsQuery = useGetOrganizationSettingsQuery();

  const orgSettings = orgSettingsQuery.data?.organizationSettings;

  if (getShipmentInvoiceDetailsQuery.isLoading) {
    return null;
  }
  return (
    <DownloadSendInvoiceButtons
      invoice={
        getShipmentInvoiceDetailsQueryByShipmentId.data?.invoiceByShipmentId ||
        getShipmentInvoiceDetailsQueryByInvoiceId.data?.invoiceById ||
        null
      }
      generating={generateInvoiceMutation.isLoading}
      sending={sendInvoiceMutation.isLoading}
      canGenerateInvoice={canGenerateInvoice}
      canSetTaxRate={!!orgSettings?.invoicing?.enableTaxRate}
      onGenerate={async (invoiceNumber) => {
        if (!canGenerateInvoice) {
          return;
        }
        if (!shipmentId) {
          return;
        }
        try {
          const result = await generateInvoiceMutation.mutateAsync({
            shipmentId,
            invoiceNumber,
          });
          await getShipmentInvoiceDetailsQuery.refetch();
          enqueueSnackbar(t("invoiceIsGenerating"));
          window.analytics?.track("Invoice Generated", {
            shipmentId,
            invoiceId: result.generateInvoice._id,
          });
          window.analytics?.identify({
            invoiceGenerated: true,
            lastInvoiceGenerationDate: new Date(),
            lastInvoiceGenerationDateOnly: new Date()
              .toISOString()
              .split("T")[0],
            numberOfInvoicesGenerated:
              (window.analytics?.user?.()?.traits?.()
                ?.numberOfInvoicesGenerated || 0) + 1,
          });
          window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            invoiceGenerated: true,
            lastInvoiceGenerationDate: new Date(),
            lastInvoiceGenerationDateOnly: new Date()
              .toISOString()
              .split("T")[0],
            numberOfInvoicesGenerated:
              (window.analytics?.group?.()?.traits?.()
                ?.numberOfInvoicesGenerated || 0) + 1,
          });
        } catch (error) {
          showDialog({
            type: "error",
            title: t("errorGeneratingInvoice"),
            // An error occurred while generating the invoice
            description: t("errorGeneratingInvoiceDescription", {
              errorMessage:
                (error as Error).message || t("common:error.unknownError"),
            }),
            // "Error generating the invoice. " + (error as Error).message ||
            // "Unknown error",
          });
        }
      }}
      onSendInvoice={async () => {
        try {
          await sendInvoiceMutation.mutateAsync({
            invoiceId: invoice?._id || "",
          });
          await updateInvoiceStatusMutation.mutateAsync({
            invoiceId: invoice?._id || "",
            status: InvoiceStatus.Closed,
          });
          await queryClient.refetchQueries(
            useGetInvoiceDetailsQuery.getKey({
              id: invoice?._id || "",
            })
          );
          enqueueSnackbar(t("invoiceSuccessfullySent"));
          window.analytics?.identify({
            invoiceSent: true,
            lastInvoiceSentDate: new Date(),
            lastInvoiceSentDateOnly: new Date().toISOString().split("T")[0],
            numberOfInvoicesSent:
              (window.analytics?.user?.()?.traits?.()?.numberOfInvoicesSent ||
                0) + 1,
          });
          window.analytics?.group(window.analytics?.group?.()?.id?.(), {
            invoiceSent: true,
            lastInvoiceSentDate: new Date(),
            lastInvoiceSentDateOnly: new Date().toISOString().split("T")[0],
            numberOfInvoicesSent:
              (window.analytics?.group?.()?.traits?.()?.numberOfInvoicesSent ||
                0) + 1,
          });
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t("errorSendingInvoice"),
            description: (e as Error).message || t("common:error.unknownError"),
          });
        }
      }}
      onShowPdfGenerationError={() => {
        showDialog({
          type: "error",
          title: t("errorGeneratingInvoicePdf"),
          description:
            invoice?.pdfDocument.error || t("common:error.unknownError"),
        });
      }}
      onTaxRateChange={async (taxRate) => {
        try {
          await updateInvoiceTaxRateMutation.mutateAsync({
            invoiceId: invoice?._id || "",
            taxRate: taxRate || null,
          });
          await getShipmentInvoiceDetailsQuery.refetch();
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t("errorUpdatingInvoiceTaxRate"),
            description: (e as Error).message || t("common:error.unknownError"),
          });
        }
      }}
      onMarkAsPaid={async (paidAmount) => {
        try {
          await updateInvoiceStatusMutation.mutateAsync({
            invoiceId: invoice?._id || "",
            status: InvoiceStatus.Paid,
            paidAmount,
          });
          await getShipmentInvoiceDetailsQuery.refetch();
          enqueueSnackbar(t("invoiceMarkedAsPaid"));
        } catch (e) {
          console.error(e);
          showDialog({
            type: "error",
            title: t("errorMarkingInvoiceAsPaid"),
            description: (e as Error).message || t("common:error.unknownError"),
          });
        }
      }}
    />
  );
};

export default DownloadSendInvoiceButtonsContainer;

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFieldContext,
  GetGroupsQuery,
  GetOrgUsersListQuery,
  NewDriverInput,
  NewOrgUserInput,
} from "../../../graphql/generated";
import roleLabel from "../../../utils/labels/roleLabel";
import DriversList from "../../asset-management/DriversList";
import { TableField } from "../../common/LynksTable/LynksTable";
import SmartLynksTable, {
  DeleteMutation,
  QueryFn,
} from "../../common/SmartLynksTable/SmartLynksTable";
import ExcelImporter from "../../common/ExcelImporter";
import adminsMapping from "../../../utils/mapping/admins";
import userSchema from "../UserForm/userSchema";
import driversMapping from "../../../utils/mapping/drivers";
import driverSchema from "../../asset-management/DriverForm/driverSchema";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import GroupFormContainer from "../../asset-management/GroupForm";

type UserListItemData = GetOrgUsersListQuery["orgUsers"]["data"][0];
type UserListProps = {
  queryHook: QueryFn<UserListItemData, "orgUsers">;
  groupsQueryHook: QueryFn<GroupListItemData, "groups">;
  groupDeleteMutation: DeleteMutation;
  onBulkCreateAdmins: (admins: NewOrgUserInput[]) => Promise<any>;
  onBulkCreateDrivers: (drivers: NewDriverInput[]) => Promise<any>;
  onGroupsRefresh: () => void;
};

type GroupListItemData = GetGroupsQuery["groups"]["data"][0];

export enum UserRole {
  CARRIER_DRIVER = "Carrier Driver",
  CARRIER_ADMIN = "Carrier Admin",
  MANAGER = "Manager",
  DISPATCHER = "Dispatcher",
  CLERICAL = "Clerical",
  ACCOUNTING = "Accounting",
  HR = "HR",
}

enum UsersTabs {
  ADMINS = "ADMINS",
  DRIVERS = "DRIVERS",
  GROUPS = "GROUPS",
}

const UserList = ({
  queryHook: query,
  groupsQueryHook,
  groupDeleteMutation,
  onBulkCreateAdmins,
  onBulkCreateDrivers,
  onGroupsRefresh,
}: UserListProps) => {
  const { t } = useTranslation(["users", "common"]);
  const [usersTab, setUsersTab] = useState(UsersTabs.ADMINS);
  const navigate = useNavigate();
  const handleMenuClick = (popupState: any, roles: UserRole[]) => {
    popupState.close();
    if (roles.includes(UserRole.CARRIER_DRIVER)) {
      navigate("/drivers/new", {
        state: { includeAdminRole: roles.includes(UserRole.CARRIER_ADMIN) },
      });
    } else {
      navigate("/users/new", {
        state: { initialRoles: roles },
      });
    }
  };

  const [groupFormOpen, setGroupFormOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] =
    useState<GroupListItemData | null>(null);

  const fields: Array<TableField<UserListItemData>> = [
    {
      label: t("email", "Email"),
      type: "string",
      value: "email",
    },
    {
      label: t("firstName", "First Name"),
      type: "string",
      value: "firstName",
    },
    {
      label: t("lastName", "Last Name"),
      type: "string",
      value: "lastName",
    },
    {
      label: t("roles", "Roles"),
      type: "string",
      value: (user) => user.roles.map((role) => roleLabel(role)).join(", "),
      sortBy: "roles.name",
    },
    {
      value: (user) => (
        <Stack direction="row" spacing={1}>
          {user.attributes.tags?.split(",").map((tag) => (
            <Chip label={tag} />
          ))}
        </Stack>
      ),
      sortBy: "attributes.tags",
      label: t("common:tags"),
      type: "string",
    },
    {
      value: "enabled",
      label: t("assets:active", "Active"),
      type: "boolean",
      sortBy: "enabled",
    },
  ];

  const groupFields: Array<TableField<GroupListItemData>> = [
    {
      label: t("name", "Name"),
      type: "string",
      value: "name",
    },
    {
      label: t("numberOfMembers"),
      type: "number",
      value: (group) => t("memberCount", { count: group.memberIds.length }),
      sortBy: "memberIds.length",
    },
  ];

  const isAdmin = usersTab === UsersTabs.ADMINS;

  const renderHeaderActions = () => {
    return [UsersTabs.ADMINS, UsersTabs.DRIVERS].includes(usersTab) ? (
      <Stack direction="row" spacing={2}>
        <ExcelImporter
          mapping={isAdmin ? adminsMapping : driversMapping}
          schema={isAdmin ? userSchema : driverSchema}
          onBulkCreate={isAdmin ? onBulkCreateAdmins : onBulkCreateDrivers}
        />

        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <>
              <Button
                size="large"
                sx={{
                  alignSelf: "end",
                  mb: 2,
                }}
                color="secondary"
                variant="contained"
                {...bindTrigger(popupState)}
                id="new-user-button"
              >
                {t("new", "New user")}
              </Button>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() =>
                    handleMenuClick(popupState, [UserRole.CARRIER_ADMIN])
                  }
                >
                  {capitalize(t("admin.one", "Admin"))}
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleMenuClick(popupState, [UserRole.CARRIER_DRIVER])
                  }
                >
                  {capitalize(t("driver.one", "Driver"))}
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleMenuClick(popupState, [
                      UserRole.CARRIER_DRIVER,
                      UserRole.CARRIER_ADMIN,
                    ])
                  }
                >
                  {capitalize(t("adminAndDriver", "Admin & Driver"))}
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Stack>
    ) : null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={usersTab}
          onChange={(event, value) => {
            setUsersTab(value);
          }}
          aria-label="users filter tab"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label={t("admin.many", "Admins")} value={UsersTabs.ADMINS} />
          <Tab label={t("driver.many", "Drivers")} value={UsersTabs.DRIVERS} />
          <Tab label={t("group.many")} value={UsersTabs.GROUPS} />
        </Tabs>
      </Box>

      <Box
        sx={{
          height: "calc(100% - 128px)",
        }}
      >
        {isAdmin && (
          <SmartLynksTable
            query={query}
            additionalQueryVariables={{
              roles: [
                UserRole.CARRIER_ADMIN,
                UserRole.DISPATCHER,
                UserRole.MANAGER,
                UserRole.CLERICAL,
                UserRole.ACCOUNTING,
                UserRole.HR,
              ],
            }}
            dataKey="orgUsers"
            fields={fields}
            detailsUrlPrefix={(user) => {
              if (
                user.roles.map((r) => r.name).includes(UserRole.CARRIER_DRIVER)
              ) {
                return `/drivers/details/${user.attributes.driverId}`;
              } else {
                return `/users/details/${user._id}`;
              }
            }}
            actions={[
              {
                icon: null,
                tooltip: "Turn this admin into a driver as well",
                label: "Make driver",
                secondary: true,
                isApplicable(user) {
                  return !user.roles
                    .map((r) => r.name)
                    .includes(UserRole.CARRIER_DRIVER);
                },
                onClick: (user) => {
                  navigate(`/drivers/new`, { state: { user } });
                },
              },
            ]}
            customFieldContext={[CustomFieldContext.User]}
            customFieldsGetter={(user) =>
              user.attributes.customFields
                ? JSON.parse(user.attributes.customFields)
                : []
            }
            renderHeaderActions={renderHeaderActions}
          />
        )}
        {usersTab === UsersTabs.DRIVERS && (
          <DriversList renderHeaderActions={renderHeaderActions} />
        )}
        {usersTab === UsersTabs.GROUPS && (
          <SmartLynksTable
            query={groupsQueryHook}
            dataKey="groups"
            fields={groupFields}
            deleteMutation={groupDeleteMutation}
            id="groups-table"
            onRecordClick={(group) => {
              setSelectedGroup(group);
              setGroupFormOpen(true);
            }}
            renderHeaderActions={() => (
              <Button
                size="large"
                color="secondary"
                variant="contained"
                id="new-group-button"
                onClick={() => {
                  setSelectedGroup(null);
                  setGroupFormOpen(true);
                }}
              >
                {t("group.new")}
              </Button>
            )}
          />
        )}
      </Box>

      <Dialog
        open={groupFormOpen}
        onClose={() => {
          setGroupFormOpen(false);
          setSelectedGroup(null);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {capitalize(selectedGroup ? "Edit Group" : "New Group")}
        </DialogTitle>
        <DialogContent>
          {groupFormOpen ? (
            <GroupFormContainer
              groupId={selectedGroup?._id}
              onSaved={() => {
                setGroupFormOpen(false);
                onGroupsRefresh();
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UserList;

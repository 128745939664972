import { useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Breakpoint } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReceiverGoodsListContainer, { ReceiverGoodsListContainerProps } from ".";
import { GoodDistributionInput } from "../../../../graphql/generated";

export type ReceiverGoodsListModalProps = ReceiverGoodsListContainerProps & {
  openModal: boolean;
  width?: Breakpoint;
  onClose: () => void;
};

export default function ReceiverGoodsListModal({
  openModal,
  onClose,
  width,
  ...props
}: ReceiverGoodsListModalProps) {
  const [receivedGoods, setReceivedGoods] = useState<
    Array<GoodDistributionInput>
  >(props.shipmentLocation.receivedGoods);
  const { t } = useTranslation(["common", "orders"]);
  const [maxWidth] = useState<DialogProps["maxWidth"]>(width);

  const handleCancel = () => {
    onClose();
  };

  return (
    <div>
      <Dialog maxWidth={maxWidth} fullWidth open={openModal}>
        <DialogTitle>
          {t("orders:commoditiesToReceive", "Commodities to receive")}
        </DialogTitle>
        <DialogContent dividers>
          <ReceiverGoodsListContainer
            {...props}
            receivedGoods={receivedGoods}
            onChange={(receivedGoods) => {
              setReceivedGoods(receivedGoods);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("common:cancel", "Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.onChange(receivedGoods);
              onClose();
            }}
          >
            {t("common:validate", "Validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Stack, TextField } from "@mui/material";
import {
  ForecastingModel,
  ForecastingModelSlotType,
} from "../../../graphql/generated";

type WeeklyForecastModelSlotFormProps = {
  model: ForecastingModel;
  onChange: (model: ForecastingModel) => void;
};

export const WeeklyForecastModelSlotForm = (
  props: WeeklyForecastModelSlotFormProps
) => {
  const { model } = props;
  const updateAmount = (amount: number) => {
    props.onChange({
      ...model,
      slots: [
        {
          type: ForecastingModelSlotType.Weekly,
          salesAmount: amount,
        },
      ],
    });
  };

  return (
    <Stack spacing={2} direction="row">
      <TextField
        label="Sales Amount"
        value={model.slots?.[0]?.salesAmount || null}
        onChange={(event) => {
          updateAmount(parseFloat(event.target.value));
        }}
        type="number"
        fullWidth
      />
    </Stack>
  );
};

import React from "react";
import {
  OrganizationSettings,
  useGetOrganizationSettingsQuery,
} from "../../../graphql/generated";

export type OrgSettingsGuardProps = {
  evaluate: (settings: OrganizationSettings) => boolean;
  children: React.ReactNode;
};

const OrgSettingsGuard = (props: OrgSettingsGuardProps): JSX.Element | null => {
  const { data } = useGetOrganizationSettingsQuery();
  const settings = data?.organizationSettings || {};

  if (props.evaluate(settings)) {
    return <>{props.children}</>;
  }

  return null;
};

export const useOrgSettings = () => {
  const { data } = useGetOrganizationSettingsQuery();
  return data?.organizationSettings || {};
};

export default OrgSettingsGuard;

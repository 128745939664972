import { Clear } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ChangeEvent, MouseEvent as ReactMouseEvent } from "react";
import enumLabel from "../../../utils/labels/enumLabel";
import { isArray, isNumber } from "lodash";
import { useTranslation } from "react-i18next";

type EnumSelectProps<T extends string | number> = Omit<
  TextFieldProps,
  "onChange"
> & {
  enumObject: { [key: string]: T };
  onChange?: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: T | null
  ) => void;
  optionLabel?: (t: T) => string;
  value?: T | null;
  multiple?: false;
  clearable?: boolean;
  withNoneOption?: boolean;
  noneOptionLabel?: string;
};

type EnumMultiSelectProps<T extends string | number> = Omit<
  TextFieldProps,
  "onChange"
> & {
  enumObject: { [key: string]: T };
  onChange?: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | ReactMouseEvent<HTMLButtonElement, MouseEvent>,
    value: T[]
  ) => void;
  optionLabel?: (t: T) => string;
  value?: T[] | null;
  multiple: true;
  clearable?: boolean;
  withNoneOption?: boolean;
  noneOptionLabel?: string;
};

function EnumSelect<T extends string | number>({
  enumObject,
  optionLabel,
  onChange,
  multiple,
  clearable,
  withNoneOption = false,
  noneOptionLabel,
  ...props
}: EnumSelectProps<T> | EnumMultiSelectProps<T>) {
  const { t } = useTranslation("common");
  const hasValue = isArray(props.value)
    ? props.value && props.value.length
    : props.value;
  return (
    <TextField
      select
      {...props}
      value={
        hasValue
          ? props.value
          : multiple
          ? withNoneOption
            ? ["NONE"]
            : []
          : withNoneOption
          ? "NONE"
          : "NONE"
      }
      onChange={(event) => {
        if (multiple) {
          const value = event.target.value as unknown as Array<T | "NONE">;
          onChange?.(
            event,
            value[value.length - 1] === "NONE"
              ? []
              : (value.filter((v) => v !== "NONE") as Array<T>)
          );
        } else {
          onChange?.(
            event,
            event.target.value === "NONE" ? null : (event.target.value as T)
          );
        }
      }}
      SelectProps={{
        multiple,
        IconComponent: !clearable || hasValue ? () => null : undefined,
        endAdornment:
          clearable && hasValue ? (
            <InputAdornment position="end">
              <IconButton
                onClick={(event) => {
                  if (multiple) {
                    onChange?.(event, []);
                  } else {
                    onChange?.(event, null);
                  }
                }}
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      InputProps={{}}
    >
      {Object.values(enumObject)
        .filter((option) => !isNumber(option))
        .map((option) => (
          <MenuItem key={option} value={option}>
            {optionLabel ? optionLabel(option) : enumLabel(String(option))}
          </MenuItem>
        ))}
      {withNoneOption && (
        <MenuItem key={"NONE"} value={"NONE"}>
          {noneOptionLabel || t("none", "None")}
        </MenuItem>
      )}
    </TextField>
  );
}

export default EnumSelect;

import i18next from "i18next";
import { InvoiceConsolidationCriteria } from "../../graphql/generated";

const defaultLabels: Record<InvoiceConsolidationCriteria, string> = {
  BOL_NUMBER: "BOL Number",
  DAY: "Day",
  PO_NUMBER: "PO Number",
  WEEK: "Week",
};

function invoiceConsolidationCriteriaLabel(
  criteria: InvoiceConsolidationCriteria
) {
  return i18next.t(`invoiceConsolidationCriteria.${criteria}`, {
    defaultValue: defaultLabels[criteria],
  }) as string;
}

export default invoiceConsolidationCriteriaLabel;

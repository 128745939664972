import React, { useEffect } from "react";
import {
  Scalars,
  useEditInvoiceMutation,
  useGetInvoiceDetailsQuery,
} from "../../../graphql/generated";
import InvoiceDetails, { InvoiceDetailsProps } from "./InvoiceDetails";
import LoadingOverlay from "../../common/LoadingOverlay";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type InvoiceDetailsContainerProps = Omit<
  InvoiceDetailsProps,
  "invoice" | "onSave"
> & {
  invoiceId: Scalars["ObjectId"];
  onLoad?: (invoice: InvoiceDetailsProps["invoice"]) => void;
};

const InvoiceDetailsContainer: React.FC<InvoiceDetailsContainerProps> = ({
  invoiceId,
  onLoad,
  ...restProps
}) => {
  const { data, isLoading, error, refetch } = useGetInvoiceDetailsQuery({
    id: invoiceId,
  });

  const editInvoiceMutation = useEditInvoiceMutation();

  const { t } = useTranslation(["finance", "common"]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.invoiceById && onLoad) {
      onLoad(data.invoiceById);
    }
  }, [data, onLoad]);

  if (isLoading) {
    return <LoadingOverlay loading />;
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }

  const handleSave = async (updatedInvoice: InvoiceDetailsProps["invoice"]) => {
    try {
      await editInvoiceMutation.mutateAsync({
        id: invoiceId,
        editInvoiceData: {
          charges: updatedInvoice.charges,
          payments: updatedInvoice.payments,
          status: updatedInvoice.status,
        },
      });
      enqueueSnackbar(
        t("invoiceDetails.invoiceSaveSuccess", "Invoice saved successfully"),
        {
          variant: "success",
        }
      );
      await refetch();
    } catch (e) {
      // Handle error
      console.error(e);
    }
  };

  return (
    <InvoiceDetails
      invoice={data?.invoiceById}
      onSave={handleSave}
      error={error || editInvoiceMutation.error}
      saving={editInvoiceMutation.isLoading}
      {...restProps}
    />
  );
};

export default InvoiceDetailsContainer;

import { Box, Stack } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";

const DataGridCustomToolbar = (props: GridToolbarProps) => (
  <GridToolbarContainer>
    <Stack flex={1} direction="row" justifyContent="space-between">
      {props.showQuickFilter ? (
        <GridToolbarQuickFilter {...props.quickFilterProps} />
      ) : (
        <Box />
      )}
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </Box>
    </Stack>
  </GridToolbarContainer>
);

export default DataGridCustomToolbar;

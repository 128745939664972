import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetInvoiceDetailsQuery } from "../../../graphql/generated";
import { Delete, Edit } from "@mui/icons-material";
import { formatDateTime } from "../../../utils/labels/formatDateTime";
import { useTranslation } from "react-i18next";
import enumLabel from "../../../utils/labels/enumLabel";
import { formatCurrency } from "../../../utils/labels/formatCurrency";

type InvoicePaymentsListProps = {
  payments: NonNullable<GetInvoiceDetailsQuery["invoiceById"]["payments"]>;
  onEdit?: (paymentId: string) => void;
  onDelete?: (paymentId: string) => void;
  readonly?: boolean;
};

const InvoicePaymentsList: React.FC<InvoicePaymentsListProps> = ({
  payments,
  onEdit,
  onDelete,
  readonly,
}) => {
  const { t } = useTranslation(["finance", "common"]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("date", "Date")}</TableCell>
            <TableCell>{t("paymentMethod", "Method")}</TableCell>
            <TableCell>{t("amount", "Amount")}</TableCell>
            <TableCell>{t("notes", "Notes")}</TableCell>
            <TableCell>{t("common:list.actions", "Actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={payment._id}>
              <TableCell>{formatDateTime(payment.date)}</TableCell>
              <TableCell>{enumLabel(payment.paymentMethod)}</TableCell>
              <TableCell>{formatCurrency(payment.amount)}</TableCell>
              <TableCell>{payment.note}</TableCell>
              <TableCell>
                {readonly ? null : (
                  <>
                    <IconButton
                      onClick={() => {
                        onEdit?.(payment._id);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onDelete?.(payment._id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoicePaymentsList;

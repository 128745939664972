import Keycloak, { KeycloakConfig, KeycloakLogoutOptions } from "keycloak-js";
import { stage } from "../config/endpoint";

const currentClientAuthConfig: KeycloakConfig = process.env
  .REACT_APP_KEYCLOAK_URL
  ? {
      realm: "truetms",
      url: process.env.REACT_APP_KEYCLOAK_URL,
      clientId: "truetms-web",
    }
  : stage === "qa"
  ? {
      realm: "truetms",
      url: "https://qa-lynks-keycloak.tecafrik.com/auth",
      clientId: "truetms-web",
    }
  : stage === "beta"
  ? {
      realm: "truetms",
      url: "https://beta-auth.truetms.com/auth",
      clientId: "truetms-web",
    }
  : {
      realm: "truetms",
      url: "https://auth.truetms.com/auth",
      clientId: "truetms-web",
    };

const keycloak = new Keycloak(currentClientAuthConfig);

const resourceKey = "truetms-api";

export default keycloak;

const successCallbacks: (() => void)[] = [];
const errorCallbacks: (() => void)[] = [];

export function registerSuccessCallback(callback: () => void) {
  successCallbacks.push(callback);
}

export function registerErrorCallback(callback: () => void) {
  errorCallbacks.push(callback);
}

const cachedAccessToken = localStorage.getItem("keycloak::accessTokenCache");
const cachedRefreshToken = localStorage.getItem("keycloak::refreshTokenCache");
const cachedIdToken = localStorage.getItem("keycloak::idTokenCache");

keycloak.onAuthSuccess = () => {
  console.log("keycloak.onSuccess");
  if (keycloak.token && keycloak.refreshToken && keycloak.idToken) {
    localStorage.setItem("keycloak::accessTokenCache", keycloak.token);
    localStorage.setItem("keycloak::refreshTokenCache", keycloak.refreshToken);
    localStorage.setItem("keycloak::idTokenCache", keycloak.idToken);
  }

  // Migrate table state from v2 to v3 and add organization id to the key to ensure backward compatibility
  if (Object.keys(keycloak.tokenParsed?.organization)?.[0]) {
    localStorage.setItem(
      "keycloak::orgId",
      Object.keys(keycloak.tokenParsed?.organization)[0]
    );
  }

  const OLD_TABLE_STATE_PREFIX = "truetms-table-state-v2";
  const ORG_TABLE_STATE_PREFIX = `truetms-table-state-v3::${
    Object.keys(keycloak.tokenParsed?.organization)[0]
  }`;

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(OLD_TABLE_STATE_PREFIX)) {
      const newKey = key.replace(
        OLD_TABLE_STATE_PREFIX,
        ORG_TABLE_STATE_PREFIX
      );
      const value = localStorage.getItem(key);
      localStorage.setItem(newKey, value || "");
      localStorage.removeItem(key);
    }
  }

  successCallbacks.forEach((successCallback) => successCallback());
};
keycloak.onAuthError = (error) => {
  errorCallbacks.forEach((errorCallback) => errorCallback());
  keycloak.login({
    redirectUri: window.location.origin,
  });
};

keycloak.onReady = (authenticated) => {
  console.log("keycloak.onReady", authenticated);
};

const originalKcLogout = keycloak.logout;

keycloak.logout = (options: KeycloakLogoutOptions) => {
  localStorage.removeItem("keycloak::accessTokenCache");
  localStorage.removeItem("keycloak::refreshTokenCache");
  localStorage.removeItem("keycloak::idTokenCache");
  return originalKcLogout(options);
};

// the tracking page doesn't need authentication
if (process.env.NODE_ENV !== "test" && !isAnonymousPage()) {
  keycloak
    .init({
      checkLoginIframe: false,
      scope: "openid offline_access",
      token: cachedAccessToken || undefined,
      refreshToken: cachedRefreshToken || undefined,
      idToken: cachedIdToken || undefined,
    })
    .then(async function (authenticated) {
      if (window.location.pathname.startsWith("/register")) {
        if (!authenticated) {
          keycloak.register({
            redirectUri: window.location.origin + window.location.search,
          });
        }
      } else if (!authenticated) {
        keycloak.login({
          redirectUri: window.location.origin,
        });
      }
    })
    .catch(function (error) {
      console.error("Failed to initialize Keycloak", error);
    });
}

let urlToken = new URLSearchParams(window.location.search).get("token");
if (urlToken) {
  localStorage.setItem("businessEntityAccessToken", urlToken);
}

function getCurrentClientAuthConfig() {
  return currentClientAuthConfig;
}

export { getCurrentClientAuthConfig, resourceKey };

export function isAnonymousPage() {
  return (
    window.location.pathname.startsWith("/tracking") ||
    window.location.pathname.startsWith("/customer-portal")
  );
}

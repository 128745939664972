import { Alert } from "@mui/material";
import { omit } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetGoodProfileDetailsQuery,
  useAddGoodProfileClassMutation,
  useAddGoodProfileMutation,
  useDeleteGoodProfileClassMutation,
  useEditGoodProfileMutation,
  useGetGoodProfileClassListQuery,
  useGetGoodProfileDetailsQuery,
  useGetGoodProfileListQuery,
} from "../../../graphql/generated";
import { showDialog } from "../../../redux/slices/alert/Alert.slice";
import LoadingOverlay from "../../common/LoadingOverlay";
import GoodProfileForm from "./GoodProfileForm";
import { useSnackbar } from "notistack";

type GoodProfileFormContainerProps = {
  goodProfileId?: string;
  onLoad?: (goodProfile: GetGoodProfileDetailsQuery["goodProfileById"]) => void;
};

function GoodProfileFormContainer({
  goodProfileId,
  onLoad,
}: GoodProfileFormContainerProps) {
  const { enqueueSnackbar } = useSnackbar();
  const addGoodProfileMutation = useAddGoodProfileMutation();
  const editGoodProfileMutation = useEditGoodProfileMutation();
  const goodProfilesQuery = useGetGoodProfileListQuery();
  const goodProfileClassesQuery = useGetGoodProfileClassListQuery();
  const addGoodProfileClassMutation = useAddGoodProfileClassMutation();
  const deleteGoodProfileClassMutation = useDeleteGoodProfileClassMutation();
  const initialGoodProfileId = useRef(goodProfileId);

  // We call this hook conditionally because the component
  // should never rerender with a different value for
  // businessEntityId, but if it does we don't update
  // the ref anyway so this is safe
  const getGoodProfileQuery = initialGoodProfileId.current
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetGoodProfileDetailsQuery(
        {
          id: initialGoodProfileId.current,
        },
        {
          refetchOnWindowFocus: false,
          retry: false,
        }
      )
    : null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (getGoodProfileQuery?.data?.goodProfileById) {
      onLoad?.(getGoodProfileQuery.data?.goodProfileById);
    }
  }, [onLoad, getGoodProfileQuery?.data]);

  if (goodProfileId) {
    if (getGoodProfileQuery?.isLoading) {
      return <LoadingOverlay loading />;
    }
    if (getGoodProfileQuery?.error) {
      return (
        <Alert severity="error">
          An error occurred while fetching the goodProfile.{" "}
          {(getGoodProfileQuery?.error as Error).message || "Unknown error"}
        </Alert>
      );
    }
  }

  return (
    <GoodProfileForm
      initialGoodProfile={getGoodProfileQuery?.data?.goodProfileById}
      saving={
        addGoodProfileMutation.isLoading || editGoodProfileMutation.isLoading
      }
      goodProfiles={goodProfilesQuery.data?.goodProfiles?.data || []}
      goodProfileClases={
        goodProfileClassesQuery.data?.goodProfileClasses?.data || []
      }
      onSave={async (goodProfile) => {
        try {
          if (goodProfileId) {
            const result = await editGoodProfileMutation.mutateAsync({
              id: goodProfileId,
              editGoodProfileData: omit(goodProfile, "_id", "goodProfileClass"),
            });
            navigate(`/good-profiles/details/${result.editGoodProfile._id}`);
          } else {
            const result = await addGoodProfileMutation.mutateAsync({
              newGoodProfileData: omit(goodProfile, "goodProfileClass"),
            });
            navigate(`/good-profiles/details/${result.addGoodProfile._id}`);
            window.analytics?.track("GoodProfile Created", {
              goodProfileId: result.addGoodProfile._id,
            });
            window.analytics?.identify({
              goodProfileCreated: true,
              lastGoodProfileCreationDate: new Date(),
              lastGoodProfileCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfGoodProfilesCreated:
                (window.analytics?.user?.()?.traits?.()
                  ?.numberOfGoodProfilesCreated || 0) + 1,
            });
            window.analytics?.group(window.analytics?.group?.()?.id?.(), {
              goodProfileCreated: true,
              lastGoodProfileCreationDate: new Date(),
              lastGoodProfileCreationDateOnly: new Date()
                .toISOString()
                .split("T")[0],
              numberOfGoodProfilesCreated:
                (window.analytics?.group?.()?.traits?.()
                  ?.numberOfGoodProfilesCreated || 0) + 1,
            });
          }
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while ${
                  goodProfileId ? "editing" : "creating"
                } the goodProfile. ` + (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      onGoodProfileClassAdd={async (newGoodProfileClass) => {
        try {
          const {
            addGoodProfileClass: { _id },
          } = await addGoodProfileClassMutation.mutateAsync({
            newGoodProfileClassData: newGoodProfileClass,
          });
          await goodProfileClassesQuery.refetch();
          return _id;
        } catch (error) {
          dispatch(
            showDialog({
              title: "Error",
              description:
                `An error occurred while adding the goodProfile class. ` +
                (error as Error).message,
              type: "error",
            })
          );
        }
      }}
      onGoodProfileClassDelete={async (goodProfileClassId) => {
        try {
          await deleteGoodProfileClassMutation.mutateAsync({
            id: goodProfileClassId,
          });
          await goodProfileClassesQuery.refetch();
          enqueueSnackbar("GoodProfile class deleted successfully", {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar(
            `An error occurred while deleting the goodProfile class. ` +
              (error as Error).message,
            {
              variant: "error",
            }
          );
        }
      }}
    />
  );
}

export default GoodProfileFormContainer;
